




















































  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-nocheck
import Vue from 'vue';
import { DEFAULT_GRANULARITY } from '@shared/config';
import LineChart from '../chartjs/LineChart.vue';
import { LSS } from '@core/services';
import ChartPopup from '../ads-manager/ChartPopup.vue';
import { numberFormat } from 'src/utils/util';
import { SARMetricsFields, SARMetricsFormattedFields } from '@shared/enums';
export default Vue.extend({
  name: 'Chart',
  components: {
    LineChart,
    ChartPopup
  },
  props: {
    chartdata: {
      type: Object,
      default() { return { labels: [], datasets: [] } }
    },
    options: {
      type: Object,
      default() { return {}; }
    },
    granularity: {
      type: String,
      default:DEFAULT_GRANULARITY
    },
  },
  data() {
    return {
      visible:false,
      oldData: null,
      selectedDatasets: [],
      datasetsList: [],
      linechartData: { labels: [], datasets: [], datasetsList:[]},
      selectedSetNamesToPersist: [],
      // defaultDataset : ['Rules Ran', 'Reports Sent', 'Campaigns Paused']
      defaultDataset : [SARMetricsFormattedFields.rule_run, SARMetricsFormattedFields.cpg, SARMetricsFormattedFields.tapInstallCPI, SARMetricsFormattedFields.keywords, SARMetricsFormattedFields.impressions, SARMetricsFormattedFields.taps, SARMetricsFormattedFields.tapInstalls, SARMetricsFormattedFields.goal_count, SARMetricsFormattedFields.spend, SARMetricsFormattedFields.cpt]
    };
  },
  computed: {
    granularityList: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('dropdowns.daily'),
            value: 'DAILY'
          },
          // {
          //   text: this.$t('dropdowns.weekly'),
          //   value: 'WEEKLY'
          // },
          // {
          //   text: this.$t('dropdowns.monthly'),
          //   value: 'MONTHLY'
          // },
        ];
      }
    },
  },
  watch: {
    chartdata: function(data) {
      const compare = _.cloneDeep(data);

      delete compare.time;

      const local = _.cloneDeep({
        ...data,
        // datasets: data.datasets,
        datasets: data.datasets.map(dataset => {
          if([SARMetricsFormattedFields.keyword_bid_decreased, SARMetricsFormattedFields.keyword_bid_increased, SARMetricsFormattedFields.campaign_budget_decreased, SARMetricsFormattedFields.campaign_budget_increased, SARMetricsFormattedFields.cpa, SARMetricsFormattedFields.cpg, SARMetricsFormattedFields.cpt, SARMetricsFormattedFields.roas, SARMetricsFormattedFields.spend, SARMetricsFormattedFields.revenue].includes(dataset.label)){
            return {
              ...dataset,
              data: dataset.data.map(item => item? item.toFixed(2): 0.00)
            }
          }else{
            return {
              ...dataset,
              data: dataset.data
            }
          }
        })
      });
      compare.datasets = compare.datasets.map(set => Object.assign({ data: set.data }));

      if(!_.isEqual(this.oldData, compare)) {
        this.oldData = compare;
        //this.datasetsList = local.datasets;
        this.datasetsList = local.datasets.filter((set)=>set.label!="LAT Off Installs" && set.label!="LAT On Installs" );

        //to remove attribution datasets from accounts tab
      //   if(this.tabInfo == 'accounts' || this.tabInfo == 'apps') {
      //     this.datasetsList = this.datasetsList.slice(0,11)
      //   }

      //   if(LSS.chartDatasets){
      //     this.setDataset();
      //   }
        // if(this.customView && this.customView!='0'){
          // this.setCustomView();
        // }
        // this.selectedDatasets = this.selectedSetNamesToPersist.length ? this.datasetsList.filter((set) => this.selectedSetNamesToPersist.includes(set.label)) : this.datasetsList.slice(0, 1);
        this.selectedDatasets = this.selectedSetNamesToPersist.length ? this.datasetsList.filter((set) => this.selectedSetNamesToPersist.includes(set.label)) : this.datasetsList.filter((set) => this.defaultDataset.includes(set.label));
        this.setYaxis(this.selectedDatasets)
        this.linechartData = { labels: local.labels, datasets: this.selectedDatasets , datasetsList:this.datasetsList};
      } else {
        if (this.linechartData) {
          this.setYaxis(this.selectedDatasets)
          this.linechartData = Object.assign({}, _.cloneDeep(this.linechartData), { time: Date.now() });
        }
      }
      this.options.tooltips = {
        enabled: true,
        mode: 'single',
        callbacks: {
          label: function (tooltipItems, data) {
            if([SARMetricsFormattedFields.keyword_bid_decreased, SARMetricsFormattedFields.keyword_bid_increased, SARMetricsFormattedFields.campaign_budget_decreased, SARMetricsFormattedFields.campaign_budget_increased].includes(data.datasets[tooltipItems.datasetIndex].label)){
              return data.datasets[tooltipItems.datasetIndex].label + " : $ " + tooltipItems.yLabel;
            }
            return data.datasets[tooltipItems.datasetIndex].label + " : " + tooltipItems.yLabel;
          }
        }
      }
    },
    customView:function(data) {
      this.setCustomView()
      this.selectedDatasets = this.selectedSetNamesToPersist.length ? this.datasetsList.filter((set) => this.selectedSetNamesToPersist.includes(set.label)) : this.datasetsList.filter((set) => this.defaultDataset.includes(set.label));
      this.setYaxis(this.selectedDatasets)
      this.linechartData = { labels: this.chartdata.labels, datasets: this.selectedDatasets , datasetsList:this.datasetsList };
    }

  },
  methods: {
    limiter(e) {
      if(e.length > 5) {
        this.$notify.error("You can select upto 5 datasets only")
        e.pop()
      }
    },

    makePopupVisible(){
      this.visible=true
    },
    setYaxis(datasets){
      let yAxes = [];
      datasets.map((data)=>{
        // let minY = 0
        // if(data.label === SARMetricsFormattedFields.keyword_bid_increased){
        //   minY = -1
        // }else{
        //   minY = 0
        // }
        yAxes.push({
          type: 'linear',
          position: 'left',
          gridLines: {
            display: true,
            color: data.borderColor,
            drawOnChartArea:false
          },
          ticks: {
            // min: minY     
            // maxTicksLimit:5     
            beginAtZero: true               
          },
          //max: Math.max(data.data)
          id:data.label
        })
      })
      this.options['scales'] = {
        yAxes: yAxes
      }
    },
    setDataset(){
      return
      switch (this.tabInfo){
        case "accounts":{
          const presets = LSS.chartDatasets;
          if(presets.accounts){
            this.selectedSetNamesToPersist = presets.accounts.split(",");
          }
          break;
        }
        case "apps":{
          const presets = LSS.chartDatasets;
          if(presets.apps){
            this.selectedSetNamesToPersist = presets.apps.split(",");
          }
          break;
        }

        case "campaign":
          {const presets = LSS.chartDatasets;
          if(presets.campaign){
            this.selectedSetNamesToPersist = presets.campaign.split(",");
          }
          break;}
        case "adgroup":{
          const presets = LSS.chartDatasets;
          if(presets.adgroup){
            this.selectedSetNamesToPersist = presets.adgroup.split(",");
          }
          break;}
        case "keyword":{
          const presets = LSS.chartDatasets;
          if(presets.keyword){
            this.selectedSetNamesToPersist = presets.keyword.split(",");
          }
          break;}
        case "creativeset":{
          const presets = LSS.chartDatasets;
          if(presets.creativeset){
            this.selectedSetNamesToPersist = presets.creativeset.split(",");
          }
          break;}
        case "ads":{
          const presets = LSS.chartDatasets;
          if(presets.ads){
            this.selectedSetNamesToPersist = presets.ads.split(",");
          }
          break;}
        case "searchterm":{
          const presets = LSS.chartDatasets;
          if(presets.searchterm){
            this.selectedSetNamesToPersist = presets.searchterm.split(",");
          }
          break;}
        case "negativekeyword":{
          const presets = LSS.chartDatasets;
          if(presets.negativekeyword){
            this.selectedSetNamesToPersist = presets.negativekeyword.split(",");
          }
          break;}
      }
    },

    setCustomView(){
      switch (this.tabInfo){
        case "accounts":{
          const presets = LSS.customViews;
          const preset = presets.find(o=>o._id == this.customView && o.tab == 'accounts')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = preset.ChartDatasets.split(",");
          }
          else if(presets.find(o=>o._id == '1' && o.tab == 'accounts') && presets.find(o=>o._id == '1' && o.tab == 'accounts').ChartDatasets){
            this.selectedSetNamesToPersist = presets.find(o=>o._id == '1' && o.tab == 'accounts').ChartDatasets.split(",");
          }
          else {
            this.selectedSetNamesToPersist = []
          }
          break;
        }
        case "apps":{
          const presets = LSS.customViews;
          const preset = presets.find(o=>o._id == this.customView && o.tab == 'apps')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = preset.ChartDatasets.split(",");
          }
          else if(presets.find(o=>o._id == '1' && o.tab == 'apps') && presets.find(o=>o._id == '1' && o.tab == 'apps').ChartDatasets){
            this.selectedSetNamesToPersist = presets.find(o=>o._id == '1' && o.tab == 'apps').ChartDatasets.split(",");
          }else {
            this.selectedSetNamesToPersist = []
          }
          break;
        }

        case "campaigns":{
          const presets = LSS.customViews;
          const preset = presets.find(o=>o._id == this.customView && o.tab == 'campaigns')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = preset.ChartDatasets.split(",");
          }
          else if(presets.find(o=>o._id == '1' && o.tab == 'campaigns') && presets.find(o=>o._id == '1' && o.tab == 'campaigns').ChartDatasets){
            this.selectedSetNamesToPersist = presets.find(o=>o._id == '1' && o.tab == 'campaigns').ChartDatasets.split(",");
          }else {
            this.selectedSetNamesToPersist = []
          }
          break;}
        case "adgroups":{
          const presets = LSS.customViews;
          const preset = presets.find(o=>o._id == this.customView && o.tab == 'adgroups')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = preset.ChartDatasets.split(",");
          }
          else if(presets.find(o=>o._id == '1' && o.tab == 'adgroups') && presets.find(o=>o._id == '1' && o.tab == 'adgroups').ChartDatasets){
            this.selectedSetNamesToPersist = presets.find(o=>o._id == '1' && o.tab == 'adgroups').ChartDatasets.split(",");
          }
          else {
            this.selectedSetNamesToPersist = []
          }
          break;}
        case "keywords":{
          const presets = LSS.customViews;
          const preset = presets.find(o=>o._id == this.customView && o.tab == 'keywords')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = preset.ChartDatasets.split(",");
          }
          else if(presets.find(o=>o._id == '1' && o.tab == 'keywords') && presets.find(o=>o._id == '1' && o.tab == 'keywords').ChartDatasets){
            this.selectedSetNamesToPersist = presets.find(o=>o._id == '1' && o.tab == 'keywords').ChartDatasets.split(",");
          }
          else {
            this.selectedSetNamesToPersist = []
          }
          break;}
        case "ads":{
          const presets = LSS.customViews;
          const preset = presets.find(o=>o._id == this.customView && o.tab == 'ads')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = preset.ChartDatasets.split(",");
          }
          else if(presets.find(o=>o._id == '1' && o.tab == 'ads') && presets.find(o=>o._id == '1' && o.tab == 'ads').ChartDatasets){
            this.selectedSetNamesToPersist = presets.find(o=>o._id == '1' && o.tab == 'ads').ChartDatasets.split(",");
          }
          else {
            this.selectedSetNamesToPersist = []
          }
          break;}
        case "searchterm":{
          const presets = LSS.customViews;
          const preset = presets.find(o=>o._id == this.customView && o.tab == 'searchterm')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = preset.ChartDatasets.split(",");
          }
           else if(presets.find(o=>o._id == '1' && o.tab == 'searchterm') && presets.find(o=>o._id == '1' && o.tab == 'searchterm').ChartDatasets){
            this.selectedSetNamesToPersist = presets.find(o=>o._id == '1' && o.tab == 'searchterm').ChartDatasets.split(",");
          }
          else {
            this.selectedSetNamesToPersist = []
          }
          break;}
        case "negativekeywords":{
          const presets = LSS.customViews;
          const preset = presets.find(o=>o._id == this.customView && o.tab == 'negativekeywords')
          if(preset && preset.ChartDatasets){
            this.selectedSetNamesToPersist = presets.ChartDatasets.split(",");
          }
           else if(presets.find(o=>o._id == '1' && o.tab == 'negativekeywords') && presets.find(o=>o._id == '1' && o.tab == 'negativekeywords').ChartDatasets){
            this.selectedSetNamesToPersist = presets.find(o=>o._id == '1' && o.tab == 'negativekeywords').ChartDatasets.split(",");
          }
          else {
            this.selectedSetNamesToPersist = []
          }
          break;}
      }
    },

    removeChipByLabel(label: string) {
      this.selectedDatasets = this.selectedDatasets.filter((item: { label: string }) => item.label !== label);
      this.changeSelectedDatasets();
    },

    changeSelectedDatasets(): void {
      this.selectedSetNamesToPersist = this.selectedDatasets.map((set) => set.label);
      this.setYaxis(this.selectedDatasets);
      this.linechartData = { labels: this.chartdata.labels, datasets: this.selectedDatasets ,datasetsList:this.datasetsList };

      const persistDataset = {
        tabInfo : this.tabInfo,
        selectedSetNamesToPersist:this.selectedSetNamesToPersist
      }

      this.$emit('persistDatasets',persistDataset)
      this.$emit('customViews',persistDataset)
    },
    emitGranularityChange(): void {
      this.$emit('granularityChanged', this.granularity);
      this.$emit('granularityWithTab',{tabInfo: this.tabInfo, granularity:this.granularity});
    },
    hidePopup(chartPopUp:object):void{
    if(chartPopUp[0]?.visible===false){
         this.visible=false;
     }else{
         this.selectedDatasets = chartPopUp[0]?.chartdata.datasets;
         this.changeSelectedDatasets();
      }
    }
  },

});
