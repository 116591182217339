<template flex>
  <div class="dashboard">
    <v-navigation-drawer
      app
      v-model="snapshotDialog"
      fixed
      temporary
      right
      width="450"
      class="v-fixed-right"
      style="z-index: 999999 !important"
    >
    <v-card outlined height="100%">
    <v-card-title
      class="headline d-flex justify-space-between px-5 py-3 mb-0"
      ><h5 class="text-h6">Last Run Result</h5>
      <v-icon @click="snapshotDialog=false">mdi-close</v-icon></v-card-title
    >
    <v-card-text class="pa-3 pt-0">
      <v-tabs v-model="runResultTab" grow>
        <v-tab >Basic Log</v-tab>
        <v-tab>Detail Log</v-tab>
      </v-tabs>
      <v-tabs-items v-model="runResultTab">
        <v-tab-item >
          <v-card flat>
            <v-card-text >
              <div class="keword-lists pa-3 pt-0">
                <v-row
                  class="mt-4"
                >
                  <json-viewer :expand-depth="2" :value="jsonData"></json-viewer>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item >
          <v-card flat>
            <v-card-text >
              <div class="keword-lists pa-3 pt-0">
                <v-row
                  class="mt-4"
                >
                  <json-viewer :expand-depth="1" :value="detailSARLog"></json-viewer>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      </v-card-text>
      <v-card-actions class="justify-space-between pa-3">
        <div class="right-action-btn d-flex">
          <!-- <v-btn width="127" color="grey" depressed outlined @click="snapshotDialog=false">
            Close
          </v-btn> -->
        </div>
      </v-card-actions>
      </v-card>
    </v-navigation-drawer>

    <v-app>
      <!-- Sizes your content based upon application components -->
      <div class="inner-container only-heading-with-tabs">
        <div class="top-page-header page-header-with-banner" :lang="locale">

            <v-tabs
            v-model="tab"
            background-color="transparent"
            class="custom-tab mb-3"
             center-active
                      show-arrows
          >
            <v-tab v-for="(item, i) in items" :key="i" @change="onTabChange(i)">
              <!-- {{  (i === 0 || i === 2) ? items[0] + items[2] : item }} {{ i }} -->
              {{ item }}
            </v-tab>
          </v-tabs>
        </div>

        <!-- Provides the application the proper gutter -->
        <v-container
          fluid
          class="v-main-content automation-top stepper-template"
        >
          <!-- If using vue-router -->
          <template>
            <div>
              <v-tabs-items v-model="tab">

                
                <!-- <v-tab-item>
                  <v-card
                    flat
                    elevation="3"
                    class="pa-4 rounded-lg extra-padding"
                  >
                    <v-card-text class="d-flex align-baseline  justify-end pt-0 automation-header">
                        <v-text-field
                          v-model="searchAR"
                          append-icon="mdi-magnify"
                          :label="$t('labels.filterItem')"
                          hide-details
                          class="max-275 mx-2 mb-2"
                        ></v-text-field>
                        <v-btn
                          depressed
                          class="rounded-lg v-btn-sm "
                          color="primary"
                          v-on="!isReadOnly()?{click: () => callCreateRule()}:{click: () => openPermissionReadOnlyPopup()}"
                        >
                          {{ $t('aRules.createAR') }}
                        </v-btn>
                        <CreateRule
                          @fromCreateRule="dataFromCreateRule($event)"
                          :fromRulesPage="dataToCreateRule"
                        ></CreateRule>
                    </v-card-text>

                    <v-card-text>
                      <v-row style="display: block">
                        <v-data-table
                          :search="searchAR"
                          v-model="selectedARItems"
                          :headers="arHeaders"
                          :items="arItems"
                          :items-per-page="10"
                          class="elevation-0 mt-4 table-style-3"
                          item-key="_id"
                          :loading="arLoading"
                          loading-text="Loading... Please wait"
                          fixed-header
                          :no-data-text="$t('kai.showDataText')"
                          :no-results-text="$t('kai.showDataText')"
                          :footer-props="{
                            itemsPerPageText: $t(
                              'campaignGenerator.footerProps.itemsPerPageText'
                            ),
                            itemsPerPageAllText: $t(
                              'campaignGenerator.footerProps.itemsPerPageAllText'
                            ),
                          }"
                        >
                          <template v-slot:[`item.sno`]="{ index }">
                            {{ index + 1 }}
                          </template>

                          <template v-slot:item.ruleName="{ item }">
                            <v-tooltip
                              bottom
                              v-if="
                                item.settings.ruleSettings.isIndefinite &&
                                item.arStatus === 'RUNNING'
                              "
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="secondary"
                                  dark
                                  size="18"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  all_inclusive
                                </v-icon>
                              </template>
                              <span>This rule runs indefinitely.</span>
                            </v-tooltip>
                            {{ item.ruleName }}
                          </template>

                          <template v-slot:item.arStatus="{ item }">
                            <span v-if="item.arStatus == 'CONDITIONMATCHED'">
                              CONDITION MATCHED
                            </span>
                          </template>

                          <template v-slot:item.freq="{ item }">
                            <template v-if="item.arStatus">
                            <template
                              v-if="
                                item.settings.ruleSettings.checkFrequency ==
                                RuleCheckFrequenciesEnum.EVERY_6_HRS
                              "
                            >
                              {{ $t('createAR.actionFreqsText2') }}
                            </template>
                            <template
                              v-if="
                                item.settings.ruleSettings.checkFrequency ==
                                RuleCheckFrequenciesEnum.EVERY_12_HRS
                              "
                            >
                              {{ $t('createAR.actionFreqsText3') }}
                            </template>
                            <template
                              v-if="
                                item.settings.ruleSettings.checkFrequency ==
                                RuleCheckFrequenciesEnum.EVERY_DAY
                              "
                            >
                              {{ $t('createAR.actionFreqsText4') }}
                            </template>
                          </template>
                          </template>
                          // <template v-slot:item.arStatus="{ item }">
                           //     {{ $t('createAR.' + item.arStatus) }}
                         // </template> 
                          <template v-slot:item.actions="{ item }">
                           <v-tooltip bottom color="primarydark">
                         <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="item.arStatus == 'COMPLETED'"
                              
                              class="mr-1 font-22 icon-hover"
                              color="primary"
                              @click="viewARRule(item.ruleId)"
                              dark
                             v-bind="attrs"
                             v-on="on"
                            >
                              mdi-eye-outline
                            </v-icon>
                             </template>
                            <span>Preview</span>
                          </v-tooltip>

                          <v-tooltip bottom color="primarydark">
                         <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="item.arStatus == 'COMPLETED'"
                              
                              class="mr-1 icon-hover "
                              color="primary"
                              @click="reactivateARRule(item.ruleId)"
                              dark
                               v-bind="attrs"
                             v-on="on"
                            >
                              mdi-restart
                            </v-icon>
                              </template>
                            <span>Run</span>
                          </v-tooltip>
                        <v-tooltip bottom color="primarydark">
                         <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="item.arStatus !== 'COMPLETED'"
                              
                              class="mr-1 font-22 icon-hover"
                              color="primary"
                              @click="editARRule(item.ruleId)"
                              
                              dark
                             v-bind="attrs"
                             v-on="on"
                            >
                              mdi-pencil-outline
                            </v-icon>
                              </template>
                            <span>Edit</span>
                          </v-tooltip>

                          <v-tooltip bottom color="primarydark">
                         <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              
                              class="mr-1 font-22 icon-hover"
                              color="primary"
                              @click="cloneARRule(item.ruleId)"
                              dark
                              v-bind="attrs"
                             v-on="on"
                            >
                              mdi-content-copy
                            </v-icon>
                              </template>
                            <span>Clone</span>
                          </v-tooltip>

                        <v-tooltip bottom color="primarydark">
                         <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="mr-1 icon-hover"
                              color="primary"
                              @click="deleteARRule(item.ruleId)"
                              dark
                              v-bind="attrs"
                             v-on="on"
                            >
                              mdi-delete-outline
                            </v-icon>
                              </template>
                            <span>Delete</span>
                          </v-tooltip>

                          </template>
                          <template v-slot:item.disable="{ item }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  v-on="on"
                                  v-bind="attrs"
                                  style="width: min-content"
                                >
                                  <v-switch
                                    v-if="item.arStatus == 'RUNNING'"
                                    v-model="item.arStatus"
                                    inset
                                    color="primary"
                                    true-value="RUNNING"
                                    false-value="DISABLED"
                                    class="borderedSwitch"
                                    v-on="!isReadOnly()?{click: () => disableARRule(item.ruleId)}:{click: () => openPermissionReadOnlyPopup()}"
                                    readonly
                                  ></v-switch>
                                  <v-switch
                                    inset
                                    v-if="item.arStatus == 'DISABLED'"
                                    v-model="item.arStatus"
                                    color="primary"
                                    true-value="RUNNING"
                                    false-value="DISABLED"
                                    class="borderedSwitch"
                                    v-on="!isReadOnly()?{click: () => enableARRule(item.ruleId)}:{click: () => openPermissionReadOnlyPopup()}"
                                    readonly
                                  ></v-switch>
                                </div>
                              </template>
                              <span v-if="item.arStatus == 'RUNNING'">{{
                                $t('aRules.disable')
                              }}</span>
                              <span v-if="item.arStatus == 'DISABLED'">{{
                                $t('aRules.enable')
                              }}</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item> -->

            <!-- Automated Rules tab  -->
                <v-tab-item>
                  <v-card
                    flat
                    elevation="3"
                    class="pa-4 rounded-lg extra-padding"
                  >
               <v-card-text class="pa-0  d-flex align-baseline justify-end  automation-header">
                      <!-- rule -->
                      <!-- <v-text-field
                          v-model="searchAR"
                          append-icon="mdi-magnify"
                          :label="$t('labels.filterItem')"
                          hide-details
                          class="max-275 mx-2 mb-2"
                        ></v-text-field> -->
                        
                        <!-- v-model="searchScriptedAR " -->
                        <v-text-field
                          append-icon="mdi-magnify"
                          v-model="combinedSearch"
                          :label="$t('labels.filterItem')"
                          hide-details
                          class="max-275 mx-2 mb-2"
                        ></v-text-field>
                        <template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="642"
      persistent
    >
    <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary v-btn-sm mx-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Create Rule
        </v-btn>
      </template>
    <v-card elevation="3" class="  camp-view   rounded-lg">
              <v-card flat>
                <v-card-text class="px-0 py-0">
                  <v-form class="px-3">
                  
                    <div class="sub-heading-with-btn mb-0  d-flex flex-column align-start sticky-top">
                        <h5 class=" primarybase--text h5--text-bold mb-3 w-100 d-flex justify-space-between">
                    <span> Add Rule</span>  <v-icon class="d-flex justify-end primarybase--text close" @click="dialog=false;">mdi-close</v-icon>
                    </h5>
                    <!-- <p class=" primarybase--text body--text-1 mb-0"></p> -->
                     </div>
                    <v-radio-group v-model="radioGroup" class="mt-0">
                      <v-row class="mt-0">
                        <v-col cols="12" sm="6" lg="6" md="6" xl="6" class="py-0">
                          <div 
                          v-on="!isReadOnly()?{click: () => createKWJ()}:{click: () => openPermissionReadOnlyPopup()}"
                          :class="radioGroup==0?'camp-upload-file align-start bg-active':'camp-upload-file align-start bg-hover'"  > 
                            <v-label block class="pb-4"></v-label> 
                             <v-radio :value="0">
                              <template v-slot:label>
                                <div>
                                  <h4 class=" mb-3 h7--text-bold ">Keyword Journey <sup>TM</sup></h4>
                                  <div class=" caption--text-bold">
                                    Configure our pre-defined Keyword Journey automation rule set to achieve balance between optimizing spend and exploring new keywords for maximum efficiency and performance.
                                  </div>
                                </div>
                               </template>
                            </v-radio>
                          </div>
                        </v-col>
                         <!-- <v-col cols="12" sm="6" lg="6" md="6" xl="6" class="py-0">
                          <div 
                          v-on="!isReadOnly()?{click: () => callCreateRule()}:{click: () => openPermissionReadOnlyPopup()}"
                          :class="radioGroup==1?'camp-upload-file align-start bg-active':'camp-upload-file align-start bg-hover'"  >
                            <v-label block class="pb-4"></v-label>
                            <v-radio :value="1">
                              <template v-slot:label>
                                <div>
                                  <h4  class="h7--text-bold  mb-3" >Standard Rule <sup>TM</sup></h4>
                                  <div class="caption--text-bold">
                                    Let Search Ads Maven guide your tailored Apple Search Ads strategy, aligning with your unique needs within supported automation constructs.
                                  </div>
                                </div>
                               </template>
                            </v-radio>
                          </div>
                        </v-col> -->

                        <v-col cols="12" sm="6" lg="6" md="6" xl="6" class="py-0">
                          <div 
                          v-on="!isReadOnly()?{click: () => createSL()}:{click: () => openPermissionReadOnlyPopup()}"
                          :class="radioGroup==3?'camp-upload-file align-start bg-active':'camp-upload-file align-start bg-hover'"  > 
                            <v-label block class="pb-4"></v-label> 
                             <v-radio :value="3">
                              <template v-slot:label>
                                <div>
                                  <h4 class=" mb-3 h7--text-bold ">Strategic Losing <sup>TM</sup></h4>
                                  <div class=" caption--text-bold">
                                    Win when it's profitable and lose with the second highest bid when it's not. Drive your competitor's costs up and decrease competition for future auctions.
                                  </div>
                                </div>
                               </template>
                            </v-radio>
                          </div>
                        </v-col>

                        <v-col cols="12" sm="6" lg="6" md="6" xl="6" class="py-0">
                          <div 
                          v-on="!isReadOnly()?{click: () => createLDW()}:{click: () => openPermissionReadOnlyPopup()}"
                          :class="radioGroup==5?'camp-upload-file align-start bg-active':'camp-upload-file align-start bg-hover'"  > 
                            <v-label block class="pb-4"></v-label> 
                             <v-radio :value="5">
                              <template v-slot:label>
                                <div>
                                  <h4 class=" mb-3 h7--text-bold ">Late Day Winning <sup>TM</sup></h4>
                                  <div class=" caption--text-bold">
                                    Burn the competition budgets in the morning and then pick up cheaper taps in the afternoon. Great option for campaigns that often hit their daily cap.                                  </div>
                                </div>
                               </template>
                            </v-radio>
                          </div>
                        </v-col>

                         <v-col cols="12" sm="6" lg="6" md="6" xl="6" class="py-0">
                          <div 
                          v-on="!isReadOnly()?{click: () => createSAR()}:{click: () => openPermissionReadOnlyPopup()}"
                          :class="radioGroup==2?'camp-upload-file align-start bg-active':'camp-upload-file align-start bg-hover'" >
                            <v-label block class="pb-4"></v-label>
                            <v-radio :value="2">
                              <template v-slot:label>
                                <div>
                                  <h4  class="h7--text-bold mb-3 ">Advanced Rule</h4>
                                  <div class="caption--text-bold">
                                    Customize and fine-tune your automation for limitless possibilities that perfectly match your strategy.
                                  </div>
                                </div>
                               </template>
                            </v-radio>
                          </div>
                        </v-col>
                      </v-row>

                  
                    </v-radio-group>
                  </v-form>
                </v-card-text>

            </v-card>
    </v-card>

    </v-dialog>
  </div>
</template>

                        <CreateRule
                          @fromCreateRule="dataFromCreateRule($event)"
                          :fromRulesPage="dataToCreateRule"
                        ></CreateRule>
                    </v-card-text>

                    <v-card-text>
                      <v-row style="display: block">
                        <!-- <v-data-table
                          :search="searchScriptedAR"
                          v-model="selectedScriptedARItems"
                          :headers="arScriptedHeaders"
                          :items="arScriptedItems"
                          :items-per-page="10"
                          class="elevation-0 mt-4 table-style-3"
                          item-key="_id"
                          :loading="arScriptedLoading"
                          loading-text="Loading... Please wait"
                          fixed-header
                          :no-data-text="$t('kai.showDataText')"
                          :no-results-text="$t('kai.showDataText')"
                          :footer-props="{
                            itemsPerPageText: $t(
                              'campaignGenerator.footerProps.itemsPerPageText'
                            ),
                            itemsPerPageAllText: $t(
                              'campaignGenerator.footerProps.itemsPerPageAllText'
                            ),
                          }"
                        > -->
                        <!-- :search="searchScriptedAR && searchAR" -->
                            <v-data-table
                              :search="combinedSearch"
                              v-model="selectedItems"
                              :headers="mergedHeaders"
                              :items="mergedItems"
                              :items-per-page="25"
                              class="elevation-0 mt-4 table-style-3"
                              item-key="_id"
                              :loading="loading"
                              loading-text="Loading... Please wait"
                              fixed-header
                              :no-data-text="$t('kai.showDataText')"
                              :no-results-text="$t('kai.showDataText')"
                              :footer-props="{
                                itemsPerPageText: $t('campaignGenerator.footerProps.itemsPerPageText'),
                                itemsPerPageAllText: $t('campaignGenerator.footerProps.itemsPerPageAllText'),
                                itemsPerPageOptions: [25, 50, -1]
                              }"
                            >

                          <template v-slot:[`item.sno`]="{ index }">
                            {{ index + 1 }}
                          </template>

                          <template v-slot:item.rulesType="{ item }">
                            <template v-if="item.status" >
                            Advanced Rule
                          </template>
                          <template v-if="item.arStatus" >
                           Standard Rule 
                          </template>
                          </template>

                          <template v-slot:item.runEvery="{ item }">
                            <template  v-if="item.status">
                            {{ item.runEvery }} Hours
                          </template>
                          <!-- Rules Status -->
                          <template v-if="item.arStatus">
                            <template
                              v-if="
                                item.settings.ruleSettings.checkFrequency ==
                                RuleCheckFrequenciesEnum.EVERY_6_HRS
                              "
                            >
                              {{ $t('createAR.actionFreqsText2') }}
                            </template>
                            <template
                              v-if="
                                item.settings.ruleSettings.checkFrequency ==
                                RuleCheckFrequenciesEnum.EVERY_12_HRS
                              "
                            >
                              {{ $t('createAR.actionFreqsText3') }}
                            </template>
                            <template
                              v-if="
                                item.settings.ruleSettings.checkFrequency ==
                                RuleCheckFrequenciesEnum.EVERY_DAY
                              "
                            >
                              {{ $t('createAR.actionFreqsText4') }}
                            </template>
                          </template>
                         </template>
                          
                          <template v-slot:item.disable="{ item }">
                            <v-tooltip bottom v-if="item.status">
                              <template v-slot:activator="{ on, attrs }" v-if="item.status !== 'COMPLETED'">
                                <div
                                  v-on="on"
                                  v-bind="attrs"
                                  style="width: min-content"
                                >
                                  <v-switch
                                    inset
                                     class="borderedSwitch"
                                    color="primary"
                                    v-model="item.enabled"
                                    
                                    v-on="!isReadOnly()?{click: () => sarAction(item.disable? 3: 2,{_id: item._id, action: item.disable? false : true})}:{click: () => openPermissionReadOnlyPopup()}"
                                    readonly
                                  ></v-switch>
                                </div>
                              </template>
                              <span v-if="!item.disable">Click to Disable</span>
                              <span v-if="item.disable">Click to Enable</span>
                            </v-tooltip>

                            <v-tooltip bottom  v-if="item.arStatus">
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  v-on="on"
                                  v-bind="attrs"
                                  style="width: min-content"
                                >
                                  <v-switch
                                    v-if="item.arStatus == 'RUNNING'"
                                    v-model="item.arStatus"
                                    inset
                                    color="primary"
                                    true-value="RUNNING"
                                    false-value="DISABLED"
                                    class="borderedSwitch"
                                    v-on="!isReadOnly()?{click: () => disableARRule(item.ruleId)}:{click: () => openPermissionReadOnlyPopup()}"
                                    readonly
                                  ></v-switch>
                                  <v-switch
                                    inset
                                    v-if="item.arStatus == 'DISABLED'"
                                    v-model="item.arStatus"
                                    color="primary"
                                    true-value="RUNNING"
                                    false-value="DISABLED"
                                    class="borderedSwitch"
                                    v-on="!isReadOnly()?{click: () => enableARRule(item.ruleId)}:{click: () => openPermissionReadOnlyPopup()}"
                                    readonly
                                  ></v-switch>
                                </div>
                              </template>
                              <span v-if="item.arStatus == 'RUNNING'">Click to Disable</span>
                             <span v-if="item.arStatus == 'DISABLED'">Click to Enable </span>
                            </v-tooltip>

                          </template>

                          <template v-slot:item.lastRun="{ item }">
                            <template v-if="item.status">
                            <v-btn small @click="openDataSet(item.lastRun, item.ruleId)" v-if="item.lastRun && item.lastRun.success === 0" class="">
                              Error
                            </v-btn>
                            <v-btn small @click="openDataSet(item.lastRun, item.ruleId)" v-else-if="item.lastRun && item.lastRun.success === 1" class="">
                              Success
                            </v-btn>
                            <v-btn  small @click="openDataSet(item.lastRun)" v-else="item.lastRun && item.lastRun.success === 2" class="">
                            Yet to run
                            </v-btn>
                          </template>
                          <template  v-if="item.arStatus">
                            <span v-if="item.arStatus == 'RUNNING'">N/A</span>
                              <span v-if="item.arStatus == 'DISABLED'">N/A</span>
                              <span v-if="item.arStatus == 'COMPLETED'">N/A</span>
                              </template>
                          </template>

                          <template v-slot:item.actions="{ item }">
                            
                            <v-tooltip bottom color="primarydark"  v-if="item.status" >
                              <template v-slot:activator="{ on, attrs }" >
                                <v-icon
                                  v-if="item.status !== 'COMPLETED' && enableSARLiveRun"
                                  @click="liveRun(item)"
                                  class="mr-1 font-22 icon-hover"
                                  :color="item.hideLiveRun ? 'secondary': 'primary'"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  {{ item.hideLiveRun ? 'mdi-timelapse mdi-spin': 'mdi-play' }}
                                </v-icon>
                              </template>
                              <span>Live run</span>
                            </v-tooltip>

                            <v-tooltip bottom color="primarydark"  v-if="item.status" >
                            <!-- @click="editARRule(item.ruleId)" rule -->
                            
                            <!-- @click="handleEdit(item)" -->
                         <template v-slot:activator="{ on, attrs }" >
                            <v-icon
                              v-if="item.status !== 'COMPLETED'"
                              @click="editSAR(item)"
                              class="mr-1 font-22 icon-hover"
                              color="primary"
                              dark
                               v-bind="attrs"
                             v-on="on"
                            >
                              mdi-pencil-outline
                            </v-icon>
                              </template>
                            <span>Edit</span>
                          </v-tooltip>

                            <!-- <v-icon
                              medium
                              class="mr-1 secondary--text"
                              color="primary"
                              @click="viewScriptedARRule(item.ruleId)"
                              dark
                            >
                              mdi-eye
                            </v-icon> -->
                            
                           
                            <v-tooltip bottom color="primarydark" v-if="item.arStatus">
                         <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="item.arStatus !== 'COMPLETED'"
                              
                              class="mr-1 font-22 icon-hover"
                              color="primary"
                              @click="editARRule(item.ruleId)"
                              
                              dark
                             v-bind="attrs"
                             v-on="on"
                            >
                              mdi-pencil-outline
                            </v-icon>
                              </template>
                            <span>Edit</span>
                          </v-tooltip>

                          <v-tooltip bottom color="primarydark" v-if="item.status">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                              class="mr-1 icon-hover"
                              @click="sarAction(1,{_id: item._id})" 
                              run
                              color="primary"
                              dark
                              v-bind="attrs"
                             v-on="on"
                            >
                              mdi-delete-outline
                            </v-icon>
                              </template>
                            <span>Delete</span>
                          </v-tooltip>
                          <v-tooltip bottom color="primarydark" v-if="item.arStatus">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                              class="mr-1 icon-hover"
                              @click="deleteARRule(item.ruleId)" 
                              run
                              color="primary"
                              dark
                              v-bind="attrs"
                             v-on="on"
                            >
                              mdi-delete-outline
                            </v-icon>
                              </template>
                            <span>Delete</span>
                          </v-tooltip>
                          <!-- Clone -->
                          <v-tooltip bottom color="primarydark">
                         <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="item.arStatus"
                              class="mr-1 font-22 icon-hover"
                              color="primary"
                              @click="cloneARRule(item.ruleId)"
                              dark
                              v-bind="attrs"
                             v-on="on"
                            >
                              mdi-content-copy
                            </v-icon>
                              </template>
                            <span>Clone</span>
                          </v-tooltip>
                          <v-tooltip bottom color="primarydark">
                         <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="item.arStatus == 'COMPLETED'"
                              class="mr-1 font-22 icon-hover"
                              color="primary"
                              @click="viewARRule(item.ruleId)"
                              dark
                             v-bind="attrs"
                             v-on="on"
                            >
                              mdi-eye-outline
                            </v-icon>
                             </template>
                            <span>Preview</span>
                          </v-tooltip>
                          
                          <!-- Run -->
                          <v-tooltip bottom color="primarydark">
                         <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="item.arStatus == 'COMPLETED'"
                              
                              class="mr-1 icon-hover "
                              color="primary"
                              @click="reactivateARRule(item.ruleId)"
                              dark
                               v-bind="attrs"
                             v-on="on"
                            >
                              mdi-restart
                            </v-icon>
                              </template>
                            <span>Run</span>
                          </v-tooltip>
                          

                          

                          
                          </template>
                        </v-data-table>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <!-- Logs tab -->
                
                <v-tab-item>
                  <v-card
                    flat
                    elevation="3"
                    class="pa-4 rounded-lg extra-padding"
                  >
                    <v-card-text
                      class="pa-0  d-flex align-baseline justify-end automation-header"
                    >
                     <v-text-field
                          v-model="searchLogs"
                          append-icon="mdi-magnify"
                          :label="$t('labels.filterItem')"
                          single-line
                          hide-details
                          class="max-275 mx-2"
                        ></v-text-field>
                        <!-- <label>{{ $t('createAR.searchLogs') }}</label> -->
                        <v-autocomplete
                          class="rounded-lg max-275"
                          v-model="searchLog"
                          :items="searchLogItems"
                          item-text="ruleName"
                          item-value="ruleId"
                          required
                          outlined
                          label="Filter the Logs by Rule"
                          dense
                          return-object
                          @change="changeLog"
                          :disabled="searchLog.length < 2"
                        >
                        </v-autocomplete>
                       
                    </v-card-text>

                    <v-card-text>
                      <v-row style="display: block">
                        <!-- <v-data-table
                          :search="searchLogs"
                          v-model="selectedARlogs"
                          :headers="logHeaders"
                          :items="logItemsFiltered"
                          :items-per-page="10"
                          class="elevation-0 mt-4 table-style-3"
                          item-key="_id"
                          :expanded.sync="expanded"
                          :single-expand="true"
                          :loading="logLoading"
                          loading-text="Loading... Please wait"
                          fixed-header
                          show-expand
                          :no-data-text="$t('kai.showDataText')"
                          :no-results-text="$t('kai.showDataText')"
                          :footer-props="{
                            itemsPerPageText: $t(
                              'campaignGenerator.footerProps.itemsPerPageText'
                            ),
                            itemsPerPageAllText: $t(
                              'campaignGenerator.footerProps.itemsPerPageAllText'
                            ),
                          }"
                        > -->

                        <!-- Scripted Rules and log in add :items -->

                        <v-data-table
                          :search="searchLogs"
                          v-model="selectedARlogs"
                          :headers="logHeaders"
                          :items="mergedDataItems"
                          :items-per-page="10"
                          class="elevation-0 mt-4 table-style-3"
                          item-key="_id"
                          :expanded.sync="expanded"
                          :single-expand="true"
                          :loading="logLoading"
                          loading-text="Loading... Please wait"
                          fixed-header
                          show-expand
                          :no-data-text="$t('kai.showDataText')"
                          :no-results-text="$t('kai.showDataText')"
                          :footer-props="{
                            itemsPerPageText: $t('campaignGenerator.footerProps.itemsPerPageText'),
                            itemsPerPageAllText: $t('campaignGenerator.footerProps.itemsPerPageAllText'),
                          }"
                        >
                          <template v-slot:[`item.sno`]="{ index }">
                            {{ index + 1 }}
                          </template>
                        
                          <template v-slot:item.logStatus="{ item }">
                            <template v-if="item.logStatus">
                            {{ $t('createAR.' + item.logStatus) }}
                          </template>
                          <template v-if="item.status">
                            <span small @click="openDataSet(item.lastRun, item.ruleId)" v-if="item.lastRun && item.lastRun.success === 0" class="">
                              ERROR
                            </span>
                            <span small @click="openDataSet(item.lastRun, item.ruleId)" v-else-if="item.lastRun && item.lastRun.success === 1" class="">
                              SUCCESS
                            </span>
                            <span  small @click="openDataSet(item.lastRun)" v-else="item.lastRun && item.lastRun.success === 2" class="">
                              YET TO RUN
                            </span>
                          </template>
                          </template>
                          <template v-slot:expanded-item="{ headers, item }" >
                            <template v-if="item.logStatus === 'SUCCESS' && item.data && item.data.messages">
                              <tr
                                v-bind:key="messageItem"
                                v-for="messageItem in item.data.messages"
                              >
                                <td :colspan="logHeaders.length">
                                  {{ messageItem }}
                                </td>
                              </tr>
                            </template>
                            <template v-if="item.logStatus === 'RUNFAILED' && item.runLogs">
                              <td :colspan="headers.length" style="padding: 0px;">
                                <v-card
                                  flat
                                  elevation="3"
                                  class="pa-4 rounded-lg extra-padding"
                                  style="border: 0px"
                                >
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Last Run At : </strong>{{ new Date(item.runLogs.runStartAt) }}                                
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Next Run At : </strong>{{ new Date(item.runLogs.runEndAt + (3600000 * getFreqValue(item.ruleFreq))) }}                                
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Run status : </strong> {{ getRunStatusString(item.runLogs.runningStep) }}                                
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                    v-if="item.runLogs && item.runLogs.errors"
                                  >
                                  <strong>Error messages : </strong>
                                    <v-list style="padding: 1px 0px;">
                                      <v-list-item
                                        v-bind:key="index"
                                        v-for="(error, index) in [0]"
                                        style="align-items: start; min-height: 12px;"
                                      >
                                        <span style="color: crimson;">{{ item.runLogs.errors[index].message }}</span>
                                        <span 
                                            style="color: crimson;"
                                            v-if="item.runLogs.errors.length > 0 && item.runLogs.errors[0].errorObject && item.runLogs.errors[0].errorObject.data && item.runLogs.errors[0].errorObject.data.error && item.runLogs.errors[0].errorObject.data.error.errors && item.runLogs.errors[0].errorObject.data.error.errors.length > 0 && item.runLogs.errors[0].errorObject.data.error.errors[0].messageCode"
                                          >  &nbsp; &nbsp; - &nbsp; &nbsp; Invalid Request to ASA
                                        </span>
                                        <span 
                                            style="color: crimson;"
                                            v-if="item.runLogs.errors.length > 0 && item.runLogs.errors[0].errorObject && item.runLogs.errors[0].errorObject.stack"
                                          >  &nbsp; &nbsp; - &nbsp; &nbsp; Unable to parse request/response object
                                        </span>
                                      </v-list-item>
                                      <v-list-item
                                        v-bind:key="index"
                                        v-if="item.runLogs.errors.length > 0 && item.runLogs.errors[0].errorObject && item.runLogs.errors[0].errorObject.data && item.runLogs.errors[0].errorObject.data.error && item.runLogs.errors[0].errorObject.data.error.errors && item.runLogs.errors[0].errorObject.data.error.errors.length > 0 && item.runLogs.errors[0].errorObject.data.error.errors[0].messageCode"
                                        style="align-items: start; min-height: 12px;"
                                      >
                                        <!-- <span style="color: crimson;">{{ item.runLogs.errors[0].errorObject.data.error.errors[0]. }}
                                        </span> -->
                                        <template>
                                          <v-simple-table>
                                            <template v-slot:default>
                                              <thead>
                                                <tr>
                                                  <th class="text-left">
                                                    S.No
                                                  </th>
                                                  <th class="text-left">
                                                    Message Code
                                                  </th>
                                                  <th class="text-left">
                                                    Error Message
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr
                                                  v-for="(error,index) in item.runLogs.errors[0].errorObject.data.error.errors"
                                                  :key="index"
                                                >
                                                  <td>{{ index + 1 }}</td>
                                                  <td>{{ error.messageCode }}</td>
                                                  <td>{{ error.message }}</td>
                                                </tr>
                                              </tbody>
                                            </template>
                                          </v-simple-table>
                                        </template>
                                      </v-list-item>
                                      <v-list-item
                                        v-if="item.runLogs.errors.length > 0 && item.runLogs.errors[0].errorObject && item.runLogs.errors[0].errorObject.stack"
                                        style="align-items: start; min-height: 12px;"
                                      >
                                        <span style="color: crimson;">{{ item.runLogs.errors[0].errorObject.message }}
                                        </span>
                                      </v-list-item>
                                    </v-list>                                  
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                    v-if="item.runLogs.infoLogs.length > 0"
                                  >
                                    <template v-for="(iLog, index) in item.runLogs.infoLogs">
                                      <span>{{ iLog.message }}</span>
                                    </template>
                                  </v-card-text>
                                </v-card>
                              </td>
                            </template>
                            <template v-if="item.logStatus === 'ASACONDITIONNOTMATCHED'">
                              <td :colspan="headers.length" style="padding: 0px;">
                                <v-card
                                  flat
                                  elevation="3"
                                  class="pa-4 rounded-lg extra-padding"
                                  style="border: 0px"
                                >
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                    <strong>Last Run At : </strong> {{ new Date(item.runLogs.runStartAt) }}                                
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Next Run At : </strong> {{ new Date(item.runLogs.runEndAt + (3600000 * getFreqValue(item.ruleFreq))) }}                                
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Run status : </strong> {{ getRunStatusString(item.runLogs.runningStep) }}                               
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Conditions Validation Results : </strong>
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                      <!-- <template
                                        v-for="(rlog, index) in item.runLogs.conditionLogsASA.responseLogs"
                                        style="align-items: start; min-height: 12px;"
                                      >
                                        <template v-for="(clog, index) in rlog.conditionLogs">
                                            <v-data-table
                                              v-if="clog.rowLogs && clog.rowLogs.length > 0"
                                              :items-per-page="clog.rowLogs.length"
                                              :headers="rowLogsheaders"
                                              :items="clog.rowLogs"
                                              hide-default-footer
                                              class="elevation-1"
                                            ></v-data-table>
                                            <v-data-table
                                              v-else
                                              :items-per-page="5"
                                              :headers="rowLogsheaders"
                                              no-data-text="No ASA reporting data response for this item. See info logs for request details"
                                              hide-default-footer
                                              class="elevation-1"
                                            ></v-data-table>
                                        </template>
                                      </template> -->
                                      <v-data-table
                                        v-if="item.runLogs.rowLogsASA && item.runLogs.rowLogsASA.length > 0"
                                        :items-per-page="item.runLogs.rowLogsASA.length"
                                        :headers="rowLogsheaders"
                                        :items="item.runLogs.rowLogsASA"
                                        hide-default-footer
                                        class="elevation-1"
                                      >
                                        <template v-slot:[`item.condition.fieldpath`]="{ item }">
                                            <template v-if="item.condition.fieldpath == 'localSpend.amount'"> Spend </template>
                                            <template v-else>{{ item.condition.fieldpath }}</template>
                                        </template>
                                      </v-data-table>
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                    v-if="item.runLogs.infoLogs.length > 0"
                                  >
                                  <strong>***Validation Logs : </strong>
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                    v-if="item.runLogs.infoLogs.length > 0"
                                  >
                                    <template v-for="(iLog, index) in item.runLogs.infoLogs">
                                      <span>{{ iLog.message }}</span>
                                    </template>
                                  </v-card-text>
                                </v-card>
                              </td>
                            </template>
                            <!-- MMP start--> 
                                <template v-if="item.logStatus === 'MMPCONDITIONNOTMATCHED'">
                              <td :colspan="headers.length" style="padding: 0px;">
                                <v-card
                                  flat
                                  elevation="3"
                                  class="pa-4 rounded-lg extra-padding"
                                  style="border: 0px"
                                >
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                    <strong>Last Run At : </strong> {{ new Date(item.runLogs.runStartAt) }}                                
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Next Run At : </strong> {{ new Date(item.runLogs.runEndAt + (3600000 * getFreqValue(item.ruleFreq))) }}                                
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Run status : </strong> {{ getRunStatusString(item.runLogs.runningStep) }}                               
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Conditions Validation Results : </strong>
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                      <!-- <template
                                        v-for="(rlog, index) in item.runLogs.conditionLogsASA.responseLogs"
                                        style="align-items: start; min-height: 12px;"
                                      >
                                        <template v-for="(clog, index) in rlog.conditionLogs">
                                            <v-data-table
                                              v-if="clog.rowLogs && clog.rowLogs.length > 0"
                                              :items-per-page="clog.rowLogs.length"
                                              :headers="rowLogsheaders"
                                              :items="clog.rowLogs"
                                              hide-default-footer
                                              class="elevation-1"
                                            ></v-data-table>
                                            <v-data-table
                                              v-else
                                              :items-per-page="5"
                                              :headers="rowLogsheaders"
                                              no-data-text="No ASA reporting data response for this item. See info logs for request details"
                                              hide-default-footer
                                              class="elevation-1"
                                            ></v-data-table>
                                        </template>
                                      </template> -->
                                      <v-data-table
                                        v-if="item.runLogs.rowLogsMMP && item.runLogs.rowLogsMMP.length > 0"
                                        :items-per-page="item.runLogs.rowLogsMMP.length"
                                        :headers="rowLogsMMPheaders"
                                        :items="item.runLogs.rowLogsMMP"
                                        hide-default-footer
                                        class="elevation-1"
                                      >
                                        <template v-slot:[`item.condition.fieldpath`]="{ item }">
                                            <template v-if="item.condition.fieldpath == 'localSpend.amount'"> Spend </template>
                                            <template v-else>{{ item.condition.fieldpath }}</template>
                                        </template>
                                      </v-data-table>
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                    v-if="item.runLogs.infoLogs.length > 0"
                                  >
                                  <strong>***Validation Logs : </strong>
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                    v-if="item.runLogs.infoLogs.length > 0"
                                  >
                                    <template v-for="(iLog, index) in item.runLogs.infoLogs">
                                      <span>{{ iLog.message }}</span>
                                    </template>
                                  </v-card-text>
                                </v-card>
                              </td>
                            </template> 

                              <!-- MMP end -->
                            <template v-if="item.logStatus === 'CREATED'">
                              <td :colspan="headers.length" style="padding: 0px;">
                                <v-card
                                  flat
                                  elevation="3"
                                  class="pa-4 rounded-lg extra-padding"
                                  style="border: 0px"
                                >
                                <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Created At : </strong> {{ new Date(item.createdOn) }}                             
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                    <strong>Last Run At : </strong> Yet to run                              
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <!-- <strong>Next Run At : </strong> {{ new Date(Date.now()+ (3600000 * getFreqValue(item.ruleFreq))) }}                                 -->
                                  <strong>Next Run At : </strong> Will run anytime before {{ new Date(new Date(item.createdOn).getTime() + (3600000 * 4)) }}
                                </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Run status : </strong> The first run will be within 4 hours from the created time. The rule's check frequency( of 6 or 12 or 24 hours) will be considered only from the second run.                            
                                  </v-card-text>
                                </v-card>
                              </td>
                            </template>
                            <template v-if="item.logStatus === 'NOCAMPAIGNSFOUND'">
                              <td :colspan="headers.length" style="padding: 0px;">
                                <v-card
                                  flat
                                  elevation="3"
                                  class="pa-4 rounded-lg extra-padding"
                                  style="border: 0px"
                                >
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Last Run At : </strong>{{ new Date(item.runLogs.runStartAt) }}                                
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Next Run At : </strong>{{ new Date(item.runLogs.runEndAt + (3600000 * getFreqValue(item.ruleFreq))) }}                                
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Run status : </strong> {{ getRunStatusString(item.runLogs.runningStep) }}                                
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                  >
                                  <strong>Error messages : </strong>
                                    <v-list style="padding: 1px 0px;">
                                      <v-list-item
                                        v-bind:key="index"
                                        v-for="(error, index) in [0]"
                                        style="align-items: start; min-height: 12px;"
                                      >
                                        <span style="color: crimson;">{{ item.runLogs.errors[index].message }}</span>
                                      </v-list-item>
                                    </v-list>                                  
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                    v-if="item.runLogs.infoLogs.length > 0"
                                  >
                                  <strong>Information Logs : </strong>
                                  </v-card-text>
                                  <v-card-text
                                    class="pa-0  d-flex align-baseline justify-end automation-header"
                                    style="justify-content: flex-start !important;"
                                    v-if="item.runLogs.infoLogs.length > 0"
                                  >
                                    <template v-for="(iLog, index) in item.runLogs.infoLogs">
                                      <span>{{ iLog.message }}</span>
                                    </template>
                                  </v-card-text>
                                </v-card>
                              </td>
                            </template>

                               <!-- Advanced Rule log -->
                              <template >
                                  <span v-if="item.lastRun && item.lastRun.success === 1"  >
                                    <json-viewer :expand-depth="2" :value="item.lastRun"></json-viewer>
                                  </span>                                
                                  <span v-if="item.lastRun && item.lastRun.success === 2" >
                                    <json-viewer :expand-depth="2" :value="item.lastRun"></json-viewer>
                                  </span>                                
                                  <span v-if="item.lastRun && item.lastRun.success === 0" >
                                    <json-viewer :expand-depth="2" :value="item.lastRun"></json-viewer>
                                  </span>
                              </template>
                          </template>

                          <template v-slot:item.logrulesType="{ item }">
                            <template v-if="item.status" >
                            {{ item.logrulesType }} Advanced Rule
                          </template>
                          <template v-if="item.logStatus">
                            {{ item.logrulesType }} Standard Rule 
                          </template>
                          </template>

                          <!-- <template v-slot:item.lastRun="{ item }">
                            <template v-if="item.status">
                            <v-btn small @click="openDataSet(item.lastRun)" v-if="item.lastRun && item.lastRun.success === 0" class="">
                              Error
                            </v-btn>
                            <v-btn small @click="openDataSet(item.lastRun)" v-else-if="item.lastRun && item.lastRun.success === 1" class="">
                              Success
                            </v-btn>
                            <v-btn  small @click="openDataSet(item.lastRun)" v-else="item.lastRun && item.lastRun.success === 2" class="">
                            Yet to run
                            </v-btn>
                          </template>
                          <template  v-if="item.arStatus">
                            <span v-if="item.arStatus == 'RUNNING'">{{
                                $t('aRules.enable')
                              }}</span>
                              <span v-if="item.arStatus == 'DISABLED'">{{
                                $t('aRules.disable')
                              }}</span>
                              </template>
                          </template> -->


                           <!-- <template v-slot:item.logStatus="{ item }">
                                <span v-if="item.logStatus == 'CREATED'" style="color:black">{{ item.logStatus }}</span>
                                <span v-else-if="item.logStatus == 'SUCCESS'" style="color:black">COMPLETED</span>
                                <span v-else-if="item.logStatus == 'SLACKSENT' && item.data && item.data.ok" style="color:darkgreen">{{ item.logStatus }}</span>
                                <span v-else-if="item.logStatus == 'SLACKSENT' || item.logStatus == 'SLACKFAIL'" style="color:crimson; text-decoration-line: underline; cursor: pointer" @click="showLogStatusMsg(item)">{{ item.logStatus }}</span>
                                <span v-else-if="item.logStatus == 'EMAILSENT' && item.data && item.data.statusCode == 202" style="color:darkgreen">{{ item.logStatus }}</span>
                                <span v-else-if="item.logStatus == 'EMAILSENT' || item.logStatus == 'EMAILFAIL'" style="color:crimson; text-decoration-line: underline; cursor: pointer" @click="showLogStatusMsg(item)">{{ item.logStatus }}</span>
                                <span v-else-if="item.data && item.data.error" style="color:crimson; text-decoration-line: underline; cursor: pointer" @click="showLogStatusMsg(item)">{{ item.logStatus }}</span>
                                <span v-else style="color:darkgreen">{{ item.logStatus }}</span>
                          </template>  -->
                        </v-data-table>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card
                    flat
                    elevation="3"
                    class="pa-4 rounded-lg extra-padding"
                  >
                    <v-row>
                      <v-col cols="12" lg="8" md="12" sm="12">
                        <Chart
                          :chartdata="metricsChartData"
                          :options="chartOptions"
                        />
                      </v-col>
                  
                   <v-col cols="12" lg="4" md="12" sm="12" class="pt-8">
                        <div>
                          <v-autocomplete
                            class="rounded-lg mt-8"
                            v-model="selectedAccount"
                            label="Select Account"
                            :items="allAccounts"
                            outlined
                            dense
                            @change="selectedAccountChanged"
                            item-text="name"
                            item-value="teamId"
                            return-object
                          ></v-autocomplete>
                        </div>   
                        <div>
                          <v-autocomplete
                            class="rounded-lg"
                            v-model="selectedRule"
                            :items="allRulesForAccount"
                            label="Select Rule"
                            outlined
                            dense
                            @change="selectedRuleChanged"
                            item-text="ruleName"
                            item-value="ruleId"
                            return-object
                            :disabled="!selectedAccount.teamId"
                          ></v-autocomplete>
                        </div>     
                        <v-label>{{updatedDaysText}}</v-label>
                        <div class=" d-flex align-center justify-end flex-wrap w-100 overview-date-picker">
                          <v-menu
                            ref="menu"
                            v-model="rangePicker"
                            :close-on-content-click="false"
                            :return-value.sync="rangePicker"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            class=" overview-menu date-picker"
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                style="font-size: revert"
                                v-model="filterDateRangeText"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                class="v-date-picker-cs w-sm-100 "
                              ></v-text-field>
                            </template>
                            <template>
                              <div style="display:flex">
                                <div style="flex:auto">
                                <ul class="flex-container column mt-3" style="list-style-type: none;">
                                  <!-- <li class="flex-item">
                                    <v-chip
                                      class="ma-1"
                                      label
                                      @click="selectPreset('TODAY')"
                                    >
                                      {{ $t('dateRangePresets.today') }}
                                    </v-chip>
                                  </li>
                                  <li class="flex-item">
                                    <v-chip
                                      class="ma-1"
                                      label
                                      @click="selectPreset('YESTERDAY')"
                                    >
                                      {{ $t('dateRangePresets.yesterday') }}
                                    </v-chip>
                                  </li> -->
                                  <li class="flex-item">
                                    <v-chip
                                      class="ma-1"
                                      label
                                      @click="selectPreset('TODAY & YESTERDAY')"
                                    >
                                    {{ $t('dateRangePresets.todayAndYeserday') }}
                                    </v-chip>
                                  </li>
                                  <li class="flex-item">
                                    <v-chip
                                      class="ma-1"
                                      label
                                      @click="selectPreset('WEEKTODATE')"
                                    >
                                    {{ $t('dateRangePresets.weekToDate') }}
                                    </v-chip>
                                  </li>
                                  <li class="flex-item">
                                    <v-chip
                                      class="ma-1"
                                      label
                                      @click="selectPreset('LASTWEEK')"
                                    >
                                      {{ $t('dateRangePresets.lastWeek') }}
                                    </v-chip>
                                  </li>
                                  <li class="flex-item">
                                    <v-chip
                                      class="ma-1"
                                      label
                                      @click="selectPreset('LASTSEVENDAYS')"
                                    >
                                      {{ $t('dateRangePresets.lastSevenDays') }}
                                    </v-chip>
                                  </li>
                                  <li class="flex-item">
                                    <v-chip
                                      class="ma-1"
                                      label
                                      @click="selectPreset('MONTHTODATE')"
                                    >
                                      {{ $t('dateRangePresets.monthToDate') }}
                                    </v-chip>
                                  </li>
                                  <li class="flex-item">
                                    <v-chip
                                      class="ma-1"
                                      label
                                      @click="selectPreset('LASTMONTH')"
                                    >
                                      {{ $t('dateRangePresets.lastMonth') }}
                                    </v-chip>
                                  </li>
                                  <li class="flex-item">
                                    <v-chip
                                      class="ma-1"
                                      label
                                      @click="selectPreset('LASTTHIRTYDAY')"
                                    >
                                      {{ $t('dateRangePresets.lastThirtyDays') }}
                                    </v-chip>
                                  </li>
                                </ul>
                              </div>
                              <div style="flex:auto">
                            <v-date-picker v-model="filterDateRangeModel"  no-title scrollable range>
                              <v-btn text color="grey" @click="rangePicker = false">Cancel</v-btn>
                              <v-btn
                                :disabled="filterDateRangeModel && filterDateRangeModel.length !== 2"
                                text
                                color="primary"
                                @click="changePeriod"
                              >OK</v-btn>
                            </v-date-picker>
                              </div>
                              </div>
                            </template>
                          </v-menu>

                        </div>               
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>

              </v-tabs-items>
            </div>
            <DecisionAlert
              :DecisionAlertIcon="DecisionAlertIcon"
              :DecisionAlert="decisionAlertFlag"
              :DecisionAlertTitle="decisionAlertTitle"
              :DecisionAlertMessage="decisionAlertMessage"
              :DecisionAlertData="decisionAlertData"
              @emitDecisionAlertCancelClicked="clearDecisionModel"
              @emitDecisionAlertOkClicked="decisionOkAction($event)"
            />
            <div class="text-center">
              <v-dialog v-model="infoAlertFlag" max-width="450" persistent>
                <v-card>
                  <v-card-title class="headline grey lighten-2">
                    {{ infoAlertTitle }}
                  </v-card-title>

                  <v-card-text>
                    <div>
                      <ul>
                        <li v-for="(msg, i) in appleMsgs" :key="i">
                          {{ msg }}
                        </li>
                      </ul>
                    </div>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="infoAlertFlag = false">
                      {{ $t('infoAlert.ok') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </template>
        </v-container>
      </div>
    </v-app>
    <PermissionReadOnlyPopup :permissionMetadata="permissionReadOnlyMetadata"></PermissionReadOnlyPopup>
  </div>
</template>

<script>
import * as _ from 'lodash';

import Vue from 'vue';
import PermissionReadOnlyPopup from './../components/PermissionReadOnlyPopup.vue';
import {
  GET_ALL_RULES,
  GET_ALL_LOGS,
  DISABLE_AR_RULE,
  DELETE_AR_RULE,
  ENABLE_AR_RULE,
  GET_ALL_RULES_ADV,
  GET_SINGLE_RULE_ADV,
  DISABLE_AR_ADV_RULE,
  ENABLE_AR_ADV_RULE,
  DELETE_AR_ADV_RULE,
  REACTIVATE_SINGLE_RULE_ADV,
  TEST_RUN_SCRIPTED_AR,
  SAVE_SCRIPTED_AR,
  GET_ALL_RULES_SCRIPTED,
  TOGGLE_SAR,
  DELETE_SAR,
  UPDATE_SCRIPTED_AR,
  GET_SAR_Metrics,
  GET_ALL_RULES_SCRIPTED_FOR_TEAM,
  GET_SAR_LOG,
  QUEUE_SAR_LIVE_RUN,
} from '../utils/arrules-queries';
import { GET_GOALS_FROM_APP, ALL_USERS } from '../utils/apollo-queries';
import CreateRule from '../components/arrules/createrule1.vue';
import DecisionAlert from '../components/DecisionAlert';
import ActionEditor from '../components/scripted-automations/ActionEditor.vue';
import TriggerEditor from '../components/scripted-automations/TriggerEditor.vue';
import { updateCLF, isReadOnly } from '../utils/util';
import { LSS } from '@core/services';
import * as moment from 'moment';
import JsonViewer from 'vue-json-viewer';
import {RuleCheckFrequencies} from '@shared/enums/arenums';
import { SARMetricsFields, SARMetricsFormattedFields } from '@shared/enums';
import Chart from '../components/scripted-automations/MetricsChart.vue';
import { DEFAULT_GRANULARITY } from '@shared/config';
import { decodeToken } from '@shared/utils/commom.util';

export default {
  name: 'Rules',
  components: {
    PermissionReadOnlyPopup,
    CreateRule,
    DecisionAlert,
    ActionEditor,
    TriggerEditor,
    JsonViewer,
    Chart,
  },
  data() {
    const today = moment().utc().format('YYYY-MM-DD');
    return {
      tid: '',
      testRunId: 'init',
      enableSARLiveRun: false,
      detailSARLog: {},
      dialog: false,
      radioGroup: 4,
      selectedItems: [],
      loading: false,
      tabItems: [
        this.$t('aRules.scriptedRules'),
        // this.$t('aRules.rules'),
        this.$t('aRules.logs'),
      ],
      metricsStartDate: moment().utc().subtract(7, 'days').format('YYYY-MM-DD'),
      metricsEndDate: moment().utc().subtract(1, 'days').format('YYYY-MM-DD'),
      filterDateRangeModel: [moment().utc().subtract(7, 'days').format('YYYY-MM-DD'),moment().utc().subtract(1, 'days').format('YYYY-MM-DD')],
      filterDateRange: [moment().utc().subtract(7, 'days').format('YYYY-MM-DD'),moment().utc().subtract(1, 'days').format('YYYY-MM-DD')],
      updatedDaysText: '',
      mobileRangePickerDialog: false,
      currentDate:new Date(today),
      previousDate:new Date(moment().utc().subtract(7, 'days').format('YYYY-MM-DD')),
      filterDateCount:7,
      rangePicker: false,
      presetStatus:false,
      selectedAccount: {
        name: 'ALL',
        teamId: null
      },
      allAccounts: [],
      selectedRule: {
        ruleName: 'ALL',
        ruleId: null
      },
      allRulesForAccount: [],
      metricsChartGranularity: DEFAULT_GRANULARITY,
      metricsDataPoints: SARMetricsFields,
      metricsFormattedFields: SARMetricsFormattedFields,
      metricsChartData: {
        labels: [],
        datasets: [],
      },
      accountsChartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      },
      chartDataSets: [],
      rowLogsheaders: [
        {
          text: 'Field',
          align: 'start',
          sortable: false,
          value: 'condition.fieldpath',
        },
        { text: 'Value', align: 'end', value: 'valueCondition' },
        { text: 'Condition', align: 'end', value: 'condition.operation' },
        { text: 'ASA Value', align: 'end', value: 'valueASA' },
        { text: 'Validation', align: 'end', value: 'compareResult' },
        { text: 'For', align: 'end', value: 'from.name' },
      ],
      rowLogsMMPheaders: [
        {
          text: 'Field',
          align: 'start',
          sortable: false,
          value: 'condition.fieldpath',
        },
        { text: 'Value', align: 'end', value: 'valueCondition' },
        { text: 'Condition', align: 'end', value: 'condition.operation' },
        { text: 'MMP Value', align: 'end', value: 'valueGoal' },
        { text: 'Validation', align: 'end', value: 'compareResult' },
        { text: 'For', align: 'end', value: 'from.name' },
      ],
      DecisionAlertIcon:2,
      existingRuleNames: [],
      existingSARRuleNames: [],
      asaInt: false,
      searchScriptedAR: '',
      searchScriptedLogs: '',
      arScriptedLoading: false,
      arScriptedItems: [],
      selectedScriptedARItems: [],
      editModeSAR: false,
      selectedSAR: null,

      templateItems: [
        {
          text: 'Pause Poorly Performing Campaigns',
          value: 'pause_poor'
        },
        {
          text: 'Fund Nearly Exhausted Campaigns',
          value: 'fund_exhausted'
        },
        {
          text: 'Reallocate Funds to Performant Campaigns',
          value: 'reallocate'
        }
      ],
      scriptedtab: null,
      jsonData: [],
      snapshotDialog: false,
      srdialog: false,
      closeSARDialog: false,
      syncStatus: false,
      locale: 'en',
      expanded: [],
      goals: [],
      decisionAlertFlag: false,
      decisionAlertTitle: 'Are you sure ?',
      decisionAlertMessage: '',
      decisionAlertData: {},
      tab: null,
      runResultTab: null,
      toptab: null,
      searchAR: '',
      searchLogs: '',
      selectedARlogs: [],
      logItems: [],
      logLoading: false,
      disableSwitch: null,
      selectedARItems: [],
      arLoading: false,
      arSearch: '',
      dataToCreateRule: {},
      fullscreenLoader: null,
      arItems: [],
      infoAlertFlag: false,
      infoAlertTitle: 'Info',
      infoAlertMessage: '',
      appleMsgs: [],
      searchLogItems: [],
      RuleCheckFrequenciesEnum: RuleCheckFrequencies,
      searchLog: {
        get() {
          return {
            ruleId: 0,
            ruleName: 'All',
          };
        },
        set(e) {
          this.searchLog.ruleId = e.ruleId;
          this.searchLog.ruleName = e.ruleName;
        },
      },
      logItemsFiltered: [],
      scriptedAutomationsModel: {
        automationSteps: [
          {
            isValid: true,
            label: 'Trigger',
            code: '',
            type: 'trigger'
          }
        ]
      },
      templateMap: {
        pause_poor: [
          {
            isValid: true,
            label: 'Trigger',
            type: 'trigger',
            template: 'campaign.tapAvgCPA > 10 and campaign.tapInstalls > 25',
            code: 'campaign.tapAvgCPA > 10 and campaign.tapInstalls > 25'
          },
          {
            isValid: true,
            label: 'Campaign Action',
            type: 'campaign',
            template: 'PauseCampaign(id)',
            code: 'PauseCampaign(id)'
          }
        ],
        fund_exhausted: [
          {
            isValid: true,
            label: 'Trigger',
            type: 'trigger',
            template: 'campaign.spend > campaign.budget - 100',
            code: 'campaign.spend > campaign.budget - 100'
          },
          {
            isValid: true,
            label: 'Campaign Action',
            type: 'campaign',
            template: 'SetCampaignBudget(id, budget + 500)',
            code: 'SetCampaignBudget(id, budget + 500)'
          },
          {
            isValid: true,
            label: 'Keyword Action',
            type: 'keyword',
            template: 'SetKeywordBidAmount(id, bid * 1.25)',
            code: 'SetKeywordBidAmount(id, bid * 1.25)'
          }
        ],
        reallocate: [
          {
            isValid: true,
            label: 'Trigger',
            type: 'trigger',
            template: 'campaign.tapAvgCPA > 1 and campaign.tapInstalls > 10 and campaign.spend < campaign.budget and campaign.state = "ACTIVE"',
            code: 'campaign.tapAvgCPA > 1 and campaign.tapInstalls > 10 and campaign.spend < campaign.budget and campaign.state = "ACTIVE"'
          },
          {
            isValid: true,
            label: 'One-Time Action',
            type: 'onetime',
            template: 'var _reallocateBudget = 0',
            code: 'var _reallocateBudget = 0'
          },
          {
            isValid: true,
            label: 'Campaign Action',
            type: 'campaign',
            template: `PauseCampaign(id);\n_reallocateBudget += budget - spend`,
            code: 'PauseCampaign(id);\n_reallocateBudget += budget - spend'
          },
          {
            isValid: true,
            label: 'Trigger',
            type: 'trigger',
            template: 'campaign.tapAvgCPA <= 1 and campaign.tapInstalls > 10 and campaign.state = "ACTIVE"',
            code: 'campaign.tapAvgCPA <= 1 and campaign.tapInstalls > 10 and campaign.state = "ACTIVE"'
          },
          {
            isValid: true,
            label: 'Trigger',
            type: 'trigger',
            template: 'ORDER BY campaign.tapAvgCPA ASC',
            code: 'ORDER BY campaign.tapAvgCPA ASC'
          },
          {
            isValid: true,
            label: 'Campaign Action',
            type: 'campaign',
            template: `_reallocateBudget += budget;\nSetCampaignBudget(id, _reallocateBudget);\nStopAction();`,
            code: `_reallocateBudget += budget;\nSetCampaignBudget(id, _reallocateBudget);\nStopAction();`
          },
        ],
      },
      triggerVarMap: {
        asaFields: ['campaign', 'adgroup', 'keyword'],
        campaignProps : ["name", "id", "state", "dailyBudget", "budget", "cpm", "spend", "tapAvgCPA", "totalAvgCPA", "cpt", "impressions", "taps", "tapInstalls", "viewInstalls", "totalInstalls", "ttr", "tapInstallRate", "totalInstallRate", "dateCreated"],
        adgroupProps : ["name", "id", "state", "spend", "tapAvgCPA", "totalAvgCPA", "cpt", "impressions", "taps", "tapInstalls", "viewInstalls", "totalInstalls", "ttr", "tapInstallRate", "totalInstallRate", "dateCreated"],
        keywordProps: ["name", "id", "state", "bid", "spend", "tapAvgCPA", "totalAvgCPA", "cpt", "impressions", "taps", "tapInstalls", "viewInstalls", "totalInstalls", "ttr", "tapInstallRate", "totalInstallRate", "dateCreated"],
      },
      scriptedArVarMap: {
        campaign: {
          functions: ['PauseCampaign(id)', 'ResumeCampaign(id)', 'SetCampaignBudget(id, budget)', 'DisableRule()', 'StopRule()', 'StopAction()', 'StopRule()', 'Log(message)'],
          vars: ['name', 'id', 'state', 'dailyBudget', 'budget', 'cpm', 'spend', 'tapAvgCPA', 'totalAvgCPA', 'cpt', 'impressions', 'taps', 'tapInstalls', 'viewInstalls', 'totalInstalls', 'ttr', 'tapInstallRate', 'totalInstallRate', 'dateCreated', 'index', 'currentDate'],
          misc: ['keyName', 'value', 'amountToSum', 'message'],
          propList: {
            currentDate: ['format()']
          }
        },
        adgroup: {
          functions: ['PauseAdgroup(id)', 'ResumeAdgroup(id)', 'DisableRule()', 'StopRule()', 'StopAction()', 'StopRule()', 'Log(message)'],
          vars: ['name', 'id', 'state', 'spend', 'tapAvgCPA', 'totalAvgCPA', 'cpt', 'impressions', 'taps', 'tapInstalls', 'viewInstalls', 'totalInstalls', 'ttr', 'tapInstallRate', 'totalInstallRate', 'dateCreated', 'index', 'currentDate'],
          misc: ['keyName', 'value', 'amountToSum', 'message'],
          propList: {
            currentDate: ['format()']
          }
        },
        keyword: {
          functions: ['PauseKeyword(id)', 'ResumeKeyword(id)', 'SetKeywordBidAmount(id, bid)', 'DisableRule()', 'StopRule()', 'StopAction()', 'StopRule()', 'Log(message)'],
          vars: ['name', 'id', 'state', 'bid', 'spend', 'tapAvgCPA', 'totalAvgCPA', 'cpt', 'impressions', 'taps', 'tapInstalls', 'viewInstalls', 'totalInstalls', 'ttr', 'tapInstallRate', 'totalInstallRate', 'dateCreated', 'index', 'currentDate'],
          misc: ['keyName', 'value', 'amountToSum', 'message'],
          propList: {
            currentDate: ['format()']
          }
        },
        onetime: {
          functions: ['PauseCampaign()', 'ResumeCampaign()', 'SetCampaignBudget()', 'PauseAdgroup()', 'ResumeAdgroup()','PauseKeyword()', 'ResumeKeyword()', 'SetKeywordBidAmount()', 'DisableRule()', 'StopRule()', 'StopAction()', 'StopRule()', 'Log()'],
          vars: [],
          misc: [],
        }
      },
      permissionReadOnlyMetadata:{},
      
    };
  },
  computed: {
       // merge log
        mergedDataItems() {
          return [...this.logItemsFiltered, ...this.mergedItems].filter(e=>!e.arStatus);
        },

        // merge two datatables Rules and Scripted Rules
          mergedHeaders() {
          const mergedHeaders = [...this.arHeaders, ...this.arScriptedHeaders];
          const uniqueHeaders = [];
          const headerValues = new Set();

          for (const header of mergedHeaders) {
          if (!headerValues.has(header.value)) {
          uniqueHeaders.push(header);
          headerValues.add(header.value);
          }
          }
          return uniqueHeaders;
          },
          mergedItems() {
          return [
          ...this.arItems, 
          ...this.arScriptedItems];
          },

          // combine two Search text-field Rules and Scripted Rules
          combinedSearch: {
          get() {
          return `${this.searchAR} ${this.searchScriptedAR}`.trim();
          },
          set(value) {
          const [searchAR, searchScriptedAR] = value.split(' ');
          this.searchAR = searchAR;
          this.searchScriptedAR = searchScriptedAR;
          this.createdd()
          }
          },
          createdd() {
          // Initialize searchAR and searchScriptedAR with default values if they are undefined or blank
          if (!this.searchAR) {
          this.searchAR = '';
          }
          if (!this.searchScriptedAR) {
          this.searchScriptedAR = '';
          }
          },
    filterDateRangeText() {
      if (this.filterDateRange[0] > this.filterDateRange[1]) {
        const maxDate = this.filterDateRange[0];
        this.filterDateRange[0] = this.filterDateRange[1];
        this.filterDateRange[1] = maxDate;
      }
      return this.filterDateRange.join(' - ');
    },
    areScriptsInvalid: {
      cache: false,
      get() {
        return this.scriptedAutomationsModel.automationSteps.find(item => !item.isValid)
      }
    },
    triggerActionPreset: {
      cache: false,
      get() {
        return this.scriptedAutomationsModel.automationSteps.find(item => item.type !== 'trigger')
      }
    },
    scriptedExpansionValue: {
      cache: false,
      get() {
        return Array.from(Array(this.scriptedAutomationsModel.automationSteps.length).keys())
      }
    },
    scripteditems: {
      cache: false,
      get() {
        return [this.$t('Functions'), this.$t('Variables')];
      },
    },
    triggeritems: {
      cache: false,
      get() {
        return [this.$t('Functions'), this.$t('Variables')];
      },
    },
    logHeaders: {
      cache: false,
      get() {
        return [
          { text: this.$t('signinSlackBtn.sno'), value: 'sno' },
          { text: this.$t('aRules.name'), value: 'ruleName' },
          // {
          //   text: this.$t('aRules.createdAt'),
          //   value: 'createdOn',
          //   default: 'NA',
          // },
          { text: this.$t('aRules.status'), value: 'logStatus', default: 'NA' },
          {
           text: 'Type',
           value: 'logrulesType',
           default: 'NA' ,
           sortable: false
        },
        // {text: this.$t('aRules.lastRun'), value: 'lastRun', default: 'NA' },

        
          {
            text: this.$t('aRules.completed'),
            value: 'updatedOn',
            default: 'NA',
          },
          { text: this.$t('createAR.details'), value: 'data-table-expand' },
          // {
          //   text: this.$t('aRules.actionFreq'),
          //   value: 'ruleFreq',
          //   default: 'NA',
          // },
          // { text: 'Actions', value: 'actions' },
        ];
      },
    },

    items: {
      cache: false,
      get() {
        // return [
        //   this.$t('aRules.rules'),
        //   this.$t('aRules.logs'),
        //   this.$t('aRules.scriptedRules'),
        //   this.$t('aRules.analytics'),
        //   this.$t('aRules.scriptedLogs'),
        // ];
        return this.tabItems
      },
      set(v) {
        this.tabItems = v
      }
    },

    ruleitems: {
      cache: false,
      get() {
        return [this.$t('Automation Rule'), this.$t('Scripted Automation Rule')];
      },
    },
    arHeaders: {
      cache: false,
      get() {
        return [
          { text: this.$t('signinSlackBtn.sno'), value: 'sno' },
          { text: this.$t('aRules.name'), value: 'ruleName' },
          { text: 'Type',
           value: 'rulesType',
           default: 'NA' ,
           sortable: false
        },
          // { text: this.$t('aRules.status'), value: 'lastRun', default: 'NA' },
          { text: 'Status', value: 'lastRun', default: 'NA' },
          {
            text: ' Frequency',
            value: 'runEvery',
            // text: this.$t('aRules.chckFreq'),
            // value: 'freq',
          },
          {
            text: this.$t('aRules.enableDisable'),
            value: 'disable',
            sortable: false,
          },
          {
            text: this.$t('aRules.actions'),
            value: 'actions',
            sortable: false,
          },
        ];
      },
    },

    arScriptedHeaders: {
      cache: false,
      get() {
        return [
          { text: this.$t('signinSlackBtn.sno'), value: 'sno' },
          { text: this.$t('aRules.name'), value: 'ruleName' },
          { text: 'Type',
           value: 'rulesType',
           default: 'NA' ,
           sortable: false
        },
          // { text: this.$t('aRules.status'), value: 'status', default: 'NA' },
          { text: 'Check Frequency', value: 'runEvery', default: '24' },
          // Type of Rules
          // { text: 'Status', value: 'lastRun', default: 'NA' },
          
          {
            text: this.$t('aRules.enableDisable'),
            value: 'disable',
            sortable: false,
          },
          // { text: 'Status', value: 'arStatus', default: 'NA' },

          {
            text: this.$t('aRules.actions'),
            value: 'actions',
            sortable: false,
          },
        ];
      },
    }

    // arItems: {
    //   cache: false,
    //   get() {
    //     return [
    //       {
    //         name: 'Rule 1',
    //         createdOn: 'Date on',
    //         action: 'Email Notification',
    //         status: 'Running',
    //         checkFreq: 'Every 3 Hrs',
    //         actionFreq: 'Every 12 Hrs',
    //       },
    //     ];
    //   },
    // },
  },
  watch: {
    rangePicker(data) {
      if(data) this.presetStatus=false;
    }
  },
  methods: {
    async liveRun(item) {
      if(item.hideLiveRun) {
        this.$notify.error(`The rule is already in process`);
        return
      }
      this.fullscreenLoader = this.$loading.show();
      if(item.hideLiveRun) item.hideLiveRun = false
      else item.hideLiveRun = true
      this.testRunId = 'LR' + (decodeToken(LSS.token).UserId).slice(0,6) + Date.now()
      const data = {
          ruleId: item.ruleId,
          user: {
              TeamID: decodeToken(LSS.token).TeamID,
              UserID: decodeToken(LSS.token).UserId,
          },
          queuedAt: Date.now(),
          genId: Date.now(),
          runHour: new Date().getUTCFullYear().toString()+(new Date().getUTCMonth() + 1).toString()+new Date().getUTCDate().toString() + new Date().getUTCHours().toString(),
          testRun: false,
          testRunId: this.testRunId,
          type: 'LR',
          data: null
      }
      // try {
        // const response = await fetch("https://api.qa.searchadsmaven.com/v1/sar/test-cloud-rule", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(data),
        // });
        // const result = await response.json();

        this.$apollo
        .query({
          query: QUEUE_SAR_LIVE_RUN,
          variables: {
            data: JSON.stringify(data),
            at: Date.now()
          },
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          if(res.data.liveRunCloud.successMessage === "OK") this.$notify.success(`Scripted rule "${item.ruleName}" is queued for a live run! Will be processed very soon`);
          else if(res.data.liveRunCloud.successMessage === "IN_PROCESS") this.$notify.error(`Scripted rule "${item.ruleName}" is not executable yet due to recent save/update`);
          else this.$notify.error(`Scripted rule "${item.ruleName}" is unable to execute`);
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error(`Unable to execute`);
        });
      // } catch (error) {
      //   console.error("Error:", error);
      //   this.fullscreenLoader.hide();
      //   this.$notify.error("Something went wrong!!!");
      // }
    },
    enableMetricsTab() {
      if (decodeToken(LSS.token) && decodeToken(LSS.token).Role == 'SUPERADMIN') {
        this.tabItems.push(this.$t('aRules.analytics'))
      }
    },
    changePeriod() {      
      // if(!['Today','Yesterday','Week to Date','Last Week', 'Last 7 Days', 'Month to Date', 'Last Month', 'Last 30 Days'].includes(this.updatedDaysText)){
      //   this.updatedDaysText='Custom';
      // }
     
      this.filterDateRange = this.filterDateRangeModel;
      this.currentDate=new Date(this.filterDateRange[1]);
      this.previousDate=new Date(this.filterDateRange[0]);
      const filterdate=this.currentDate- this.previousDate 
      this.filterDateCount= filterdate/ (1000 * 60 * 60 * 24);  
      if(!this.presetStatus){
        this.updatedDaysText='Custom';
         this.metricsStartDate =  (this.filterDateRange[0] > this.filterDateRange[1])?this.filterDateRange[1]:this.filterDateRange[0];
         this.metricsEndDate = (this.filterDateRange[0] > this.filterDateRange[1])?this.filterDateRange[0]:this.filterDateRange[1];
        
      }
      this.rangePicker = false;
      this.mobileRangePickerDialog = false;
      this.getSARMetrics();
    },
    selectPreset(preset){
      this.presetStatus=true;
      this.updatedDaysText='';
      switch(preset){
        // case 'TODAY':
        //   this.filterDateRangeModel = [moment().utc().format('YYYY-MM-DD'),moment().utc().format('YYYY-MM-DD')]
        //   this.updatedDaysText = this.$t('dateRangePresets.today')
        //   this.metricsStartDate = moment().utc().format('YYYY-MM-DD')
        //   this.metricsEndDate = moment().utc().add(1,'days').format('YYYY-MM-DD')
        //   break;
        // case 'YESTERDAY':
        //   this.filterDateRangeModel = [moment().utc().subtract(1,'days').format('YYYY-MM-DD'),moment().utc().subtract(1,'days').format('YYYY-MM-DD')]
        //   this.updatedDaysText = this.$t('dateRangePresets.yesterday');
        //   this.metricsStartDate = moment().utc().subtract(1,'days').format('YYYY-MM-DD')
        //   this.metricsEndDate = moment().utc().format('YYYY-MM-DD')
        //   break;
        case 'TODAY & YESTERDAY':
          this.filterDateRangeModel = [moment().utc().subtract(1,'days').format('YYYY-MM-DD'),moment().utc().format('YYYY-MM-DD')]
          this.updatedDaysText = this.$t('dateRangePresets.todayAndYeserday')
          this.metricsStartDate = moment().utc().subtract(1,'days').format('YYYY-MM-DD')
          this.metricsEndDate = moment().utc().format('YYYY-MM-DD')
          break;
        case 'WEEKTODATE':
          this.filterDateRangeModel = [moment().utc().startOf('week').format('YYYY-MM-DD'),moment().utc().format('YYYY-MM-DD')]
          this.updatedDaysText = this.$t('dateRangePresets.weekToDate');
          this.metricsStartDate = moment().utc().startOf('week').format('YYYY-MM-DD')
          this.metricsEndDate = moment().utc().format('YYYY-MM-DD')
          break;
        case 'LASTWEEK':
          this.filterDateRangeModel = [moment().utc().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),moment().utc().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')]
          this.updatedDaysText = this.$t('dateRangePresets.lastWeek');
          this.metricsStartDate = moment().utc().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD')
          this.metricsEndDate = moment().utc().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
          break;
        case 'LASTSEVENDAYS':
          this.filterDateRangeModel = [moment().utc().subtract(7, 'days').format('YYYY-MM-DD'),moment().utc().subtract(1, 'days').format('YYYY-MM-DD')]
          this.updatedDaysText = this.$t('dateRangePresets.lastSevenDays');
          this.metricsStartDate = moment().utc().subtract(7, 'days').format('YYYY-MM-DD'),moment().utc()
          this.metricsEndDate = moment().utc().subtract(1, 'days').format('YYYY-MM-DD')
          break;
        case 'MONTHTODATE':
          this.filterDateRangeModel = [moment().utc().startOf('month').format('YYYY-MM-DD'),moment().utc().format('YYYY-MM-DD')]
          this.updatedDaysText = this.$t('dateRangePresets.monthToDate');
          this.metricsStartDate = moment().utc().startOf('month').format('YYYY-MM-DD'),moment().utc()
          this.metricsEndDate = moment().utc().format('YYYY-MM-DD')
          break;
        case 'LASTMONTH':
          this.filterDateRangeModel = [moment().utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),moment().utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')]
          this.updatedDaysText = this.$t('dateRangePresets.lastMonth');
          this.metricsStartDate = moment().utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
          this.metricsEndDate = moment().utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
         break;
        case 'LASTTHIRTYDAY':
          this.filterDateRangeModel = [moment().utc().subtract(30, 'days').format('YYYY-MM-DD'),moment().utc().subtract(1, 'days').format('YYYY-MM-DD')]
          this.updatedDaysText = this.$t('dateRangePresets.lastThirtyDays');      
          this.metricsStartDate = moment().utc().subtract(30, 'days').format('YYYY-MM-DD')
          this.metricsEndDate = moment().utc().subtract(1, 'days').format('YYYY-MM-DD') 
         break;
      }
    },
    selectedAccountChanged() {
      if(this.selectedAccount.teamId){
        this.getAllScriptedRulesForTeam()
      }else{
        this.selectedRule = {
          ruleName: 'ALL',
          ruleId: null
        }
      }
      this.getSARMetrics();
    },
    selectedRuleChanged() {
      this.getSARMetrics();
    },
    onTabChange(tab) {
      this.updatedDaysText = this.$t('dateRangePresets.lastSevenDays');
      if(tab === 2){
        this.getSARMetrics();
        this.getAllAccounts()
      }
    },
    getFreqValue(freq) {
      switch (freq) {
        case 'EVERY_4_HRS':
          return 4
        case 'EVERY_6_HRS':
          return 6
        case 'EVERY_8_HRS':
          return 8
        case 'EVERY_12_HRS':
          return 12
        case 'EVERY_24_HRS':
          return 24
        default:
          return 24
      }
    },
    getRunStatusString(step) {
      switch (step) {
        case 1:
          return 'Invalid Integration or Invalid/Empty report responses from ASA'
        case 2:
          return 'Initial Reports fetch failed'
        case 4:
          return 'MMP conditions did not match'
        case 5:
          return 'ASA conditions did not match'
        case 8:
          return 'ASA Action Failed'
        case 9:
          return 'Send Reports Action Failed'
        default:
          return 'Error'
      }
    },
    // openDataSet(lastRun){
    //   const { success, epoch, ...rest } = lastRun
    //   this.jsonData = rest
    //   if(this.jsonData) {
    //     if(this.jsonData.LAST_RUN_AT !== 'NA') this.jsonData.LAST_RUN_AT = new Date(this.jsonData.LAST_RUN_AT).toLocaleString()
    //     if(this.jsonData.NEXT_RUN_AT.slice(0,1) !== 'W') this.jsonData.NEXT_RUN_AT = new Date(this.jsonData.NEXT_RUN_AT).toLocaleString()
    //     this.snapshotDialog = true
    //   }
    // },
    getDetailSARLog(id) {
      this.fullscreenLoader = this.$loading.show();
      this.detailSARLog = {}
      this.$apollo
        .query({
          query: GET_SAR_LOG,
          variables: {
            ruleId: id,
            now: Date.now()
          },
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          if(res.data.getSARLog && res.data.getSARLog.data){
            this.detailSARLog = JSON.parse(res.data.getSARLog.data)
            if(this.detailSARLog.messages && this.detailSARLog.messages.length > 0){
              const errs = this.detailSARLog.messages.filter(m => m.type == 'ERROR')
              Object.assign(this.detailSARLog, { error_messages : errs })
            }
          }
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
        });
    },
    openDataSet(lastRun,ruleId=null) {
      if(ruleId) this.getDetailSARLog(ruleId)
  const { success, epoch, ...rest } = lastRun;
  let arr = [];
  let err = [];
  let arr1 = [];

  err = rest.RESULT.map(e => {
    return {
      type: e.step.type,
      code: e.step.code,
      codeWithoutComments: e.step.codeWithoutComments,
      output: e.output,
      title: e.step.title,
    };
  });
  if (rest) {
    if (rest.LAST_RUN_AT) {
      // rest.LAST_RUN_AT = new Date(rest.LAST_RUN_AT).toLocaleString();
    }
    if (rest.NEXT_RUN_AT && rest.NEXT_RUN_AT.slice(0, 1) !== 'W') {
      // rest.NEXT_RUN_AT = new Date(rest.NEXT_RUN_AT).toLocaleString();
    }
     if (rest.lastASASyncTime) {
      rest.lastASASyncTime = new Date(rest.lastASASyncTime).toLocaleString();
    }
    for (const action of err) {
      if (action.type == 'trigger') {
        let campaigns = 0;
        let adgroups = 0;
        let keywords = 0;
        campaigns = action.output.rows.length;
        if (action.output.error && action.output.error.message) {
          arr.push({
              error:action.output.error.message + ` in your query - '${action.codeWithoutComments}'`
            
          });
        }
        if (campaigns == 0) {
         arr.push({
            [action.type]:
            `Trigger matched ${action.output.count.keywordCount} keywords, ${action.output.count.campaignCount} adgroups, ${action.output.count.adgroupCount} campaigns`
            
          });
          continue;
        }
        arr.push({
            [action.type]:
             `Trigger matched ${action.output.count.keywordCount} keywords, ${action.output.count.campaignCount} adgroups, ${action.output.count.adgroupCount} campaigns`
          });
      } else {
        for (const output of action.output) {
          for (const outputItemKey in output) {
            if (outputItemKey == 'log') {
              if (output[outputItemKey] == 'SAR_DISABLERULE_CALLED') {
                arr1.push('Disabling this rule');
              } else if (output[outputItemKey] == 'SAR_STOPRULE_CALLED') {
                arr1.push('Stopping this rule');
              } else if (output[outputItemKey] == 'SAR_STOPACTION_CALLED') {
                arr1.push('Stopping this action');
              } else if (output[outputItemKey] == 'SAR_NEXTRESULT_CALLED') {
                arr1.push('Moving to next result');
              } else {
                arr1.push(`ERROR: ${output[outputItemKey]}`);
              }
            }
            if (/callexpression/gi.test(outputItemKey)) {
              if (output[outputItemKey].type == 'error') {
                arr1.push(`ERROR: ${output[outputItemKey].message}`);
              } else {
                if (output[outputItemKey].humanMes) {
                  arr1.push(output[outputItemKey].humanMes);
                  delete output[outputItemKey].humanMes;
                } else if (output[outputItemKey].message) {
                  arr1.push(output[outputItemKey].message);
                } else {
                  arr1.push(output[outputItemKey]);
                }
              }
            }
          }
        }
        arr.push({ [action.title]: arr1 });
      }
    }
    this.snapshotDialog = true;
    this.jsonData = {
      LAST_RUN_AT: rest.LAST_RUN_AT,
      RESULT: arr,
      ...((rest.metric_log && rest.metric_log.length) && {
        metric_log: rest.metric_log,
          }),
      NEXT_RUN_AT: rest.NEXT_RUN_AT,
      lastASASyncTime:rest.lastASASyncTime,
      lastASASyncDelta:rest.lastASASyncDelta,
      lookback_window:rest.lookback_window
    };
  }
},
    applyTemplate(key) {
      this.editModeSAR = false
      this.scriptedAutomationsModel.automationSteps = this.templateMap[key];
    },
    changeLog() {
      if (this.searchLog.ruleId == 0) {
        this.logItemsFiltered = this.logItems;
      } else {
        this.logItemsFiltered = this.logItems.filter(
          (e) => e.ruleId == this.searchLog.ruleId
        );
      }
    },



    titleCase(s) {
      return s
        .toLowerCase()
        .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
        .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase());
    },
    clearDecisionModel() {
      this.decisionAlertFlag = false;
      this.getAllScriptedRules();
    },
    decisionOkAction(event) {
      //console.log(event);
      this.decisionAlertFlag = false;
      if(event.from === 'SAR'){
        switch (event.id) {
          case 1:
            this.deleteSAR(event.sarData._id)
            break;
          case 2:
          case 3:
            this.toggleSAR(event.sarData._id, event.sarData.action)
            break;
          case 4:
            this.saveRule()
            break;
          case 5:
            this.updateSAR(event.sarData.rule)
            break;
          default:
            break;
        }
        return
      }
      const id = event.id;
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DELETE_AR_ADV_RULE,
          variables: {
            ruleId: id,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.arItems = this.arItems.filter((x) => x._id != id);
          this.getAllRules();
          this.fullscreenLoader.hide();
          this.$notify.success(this.$t('successMsg.AutoDeleteMsg'));
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(this.$t('successMsg.requestFailMsg'));
        });
    },
    getAllRules() {
      this.arLoading = true;
      this.arItems = [];

      this.$apollo
        .query({
          query: GET_ALL_RULES_ADV,
          variables: {
            teamId: 'fad928aa-3539-495e-92e9-9ddc0e5a0897',
            now: Date.now().toString(),
          },
        })
        .then((res) => {
          if (
            res.data.getAdvAllRules == null ||
            res.data.getAdvAllRules == undefined
          ) {
            //console.log('No data');
            return;
          }
          this.arItems = res.data.getAdvAllRules;
          this.arLoading = false;
          this.existingRuleNames = res.data.getAdvAllRules.map(e => e.ruleName.toLowerCase().trim())
        })
        .catch((error) => {
          //console.log(error);
          this.arLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    getSARMetrics() {
      const filters = { 
        startDate: new Date(this.metricsStartDate).getTime(), 
        endDate: (new Date(this.metricsEndDate).getTime()) + (1000 * 60 * 60 * 24),
      }
      if(this.selectedAccount && this.selectedAccount.teamId){
        filters.teamId = this.selectedAccount.teamId
      }
      if(this.selectedRule && this.selectedRule.ruleId){
        filters.ruleId = this.selectedRule.ruleId
      }
      this.$apollo
        .query({
          query: GET_SAR_Metrics,
          variables: {
            now: Date.now(),
            filters: JSON.stringify(filters)
          },
        })
        .then(async (res) => {
          // console.log(res.data.getSARMetrics)
          const chartData = await this.prepareChartData(res.data.getSARMetrics)
        })
        .catch((error) => {
          //console.log(error);
        });
    },
    async prepareChartData(metricsData) {
      const data = {
        datasets: [],
        labels: []
      }
      metricsData.map(m => {          
        data.labels.push(m.date_mappable)
      })
      for (const key in this.metricsDataPoints) {
        if (key === 'installs' || key === 'cpa') {
          continue
        }
        if (this.metricsDataPoints.hasOwnProperty(key)) {
          const keyData = []          
          metricsData.map(m => {
            keyData.push(m[key])
          })
          const metricObj = {
            "label": this.metricsFormattedFields[key],
            "data": keyData,
            "yAxisID": this.metricsFormattedFields[key],
            "fill": false,
            "borderColor": this.getRandomColor(),
            "borderWidth": 1
          }
          data.datasets.push(metricObj)
        }
      }
      this.metricsChartData = { ...data, time: Date.now().toString() };
    },
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getAllAccounts() {
      this.fullscreenLoader = this.$loading.show();
      this.memberDetails = [];
      this.$apollo
        .query({
          query: ALL_USERS,
          variables: {},
        })
        .then((res) => {
          this.allAccounts = [];
          if(res.data.allUsers && res.data.allUsers.length > 0) {
            this.allAccounts = res.data.allUsers.map(a => {
              return {
                teamId: a.TeamID,
                name: a.CompanyName
              }
            });
            this.allAccounts.unshift({
              teamId: null,
              name: 'ALL'
            })
          }
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
        });
    },    
    getAllScriptedRulesForTeam() {
      this.arScriptedLoading = true;
      this.arScriptedItems = [];

      this.$apollo
        .query({
          query: GET_ALL_RULES_SCRIPTED_FOR_TEAM,
          variables: {
            now: Date.now(),
            teamId: this.selectedAccount.teamId
          },
        })
        .then((res) => {
          if (
            res.data.getAllScriptedRulesForTeam == null ||
            res.data.getAllScriptedRulesForTeam == undefined
          ) {
            return;
          }
          this.allRulesForAccount = res.data.getAllScriptedRulesForTeam;
          this.allRulesForAccount.unshift({
            ruleId: null,
            ruleName: 'ALL'
          })
        })
        .catch((error) => {
          //console.log(error);
          this.arScriptedLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    getAllLogs() {
      this.logLoading = true;
      this.logItems = [];

      this.$apollo
        .query({
          query: GET_ALL_LOGS,
          variables: {
            teamId: 'none',
            now: Date.now().toString(),
          },
        })
        .then((res) => {
          if (res.data.getAlllogs == null || res.data.getAlllogs == undefined) {
            //console.log('No data');
            return;
          }
          this.logItems = res.data.getAlllogs;
          this.logItems = this.logItems.map((e) => {
            const o = {
              createdOn: e.createdOn.toString().substr(0, 10),
              logId: e.logId,
              logStatus: e.logStatus,
              ruleFreq: e.ruleFreq,
              ruleId: e.ruleId,
              ruleName: e.ruleName,
              updatedOn:
                'On ' +
                moment(e.updatedOn).format('YYYY-MM-DD HH:mm').substr(0, 10) +
                ' At ' +
                moment(e.updatedOn)
                  .format('YYYY-MM-DD HH:mm')
                  .toString()
                  .substr(11, 5),
              _id: e._id,
              data: e.data,
              runLogs: e.runLogs
            };
            return o;
          });
          //console.log(this.logItems)
          this.searchLogItems = [...this.logItems];
          this.searchLogItems.unshift({
            ruleId: 0,
            ruleName: 'All',
          });
          this.searchLog.ruleId = 0;
          this.changeLog();
          this.logLoading = false;
        })
        .catch((error) => {
          //console.log(error);
          this.logLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    dataFromCreateRule(e) {
      //console.log(e)
      this.fullscreenLoader = this.$loading.show();
      if (e) {
        this.getAllRules();
        this.getAllLogs();
        updateCLF('createRule', this);
        this.dialog = false;
      }
      this.fullscreenLoader.hide();
    },
    callCreateRule() {
      const names =
        this.arItems.map((e) => e.ruleName.toLowerCase().trim()) || [];
      this.dataToCreateRule = {
        mode: 1,
        now: Date.now(),
        names: names,
      };
    },
    reactivateARRule(item) {
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.$apollo
        .query({
          query: REACTIVATE_SINGLE_RULE_ADV,
          variables: {
            ruleId: item,
          },
        })
        .then((res) => {
          this.$notify.success(this.$t('successMsg.AutoRuleEnableMsg'));
          this.getAllRules();
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(this.$t('successMsg.ruleUpdateFailMsg'));
        });
    },
    viewARRule(item) {
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.$apollo
        .query({
          query: GET_SINGLE_RULE_ADV,
          variables: {
            ruleId: item,
            now: Date.now().toString(),
          },
        })
        .then((res) => {
          if (
            res.data.getAdvSingleRules == null ||
            res.data.getAdvSingleRules == undefined
          ) {
            //console.log('No data');
            return;
          }
          const item = res.data.getAdvSingleRules[0];
          this.arLoading = false;
          const names = item.ruleName.toLowerCase().trim();
          this.dataToCreateRule = {
            mode: 3,
            now: Date.now(),
            item: item,
            goals: this.goals,
            names: this.existingRuleNames,
          };
        })
        .catch((error) => {
          //console.log(error);
          this.arLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    editARRule(item) {
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.$apollo
        .query({
          query: GET_SINGLE_RULE_ADV,
          variables: {
            ruleId: item,
            now: Date.now().toString(),
          },
        })
        .then((res) => {
          if (
            res.data.getAdvSingleRules == null ||
            res.data.getAdvSingleRules == undefined
          ) {
            //console.log('No data');
            return;
          }
          const item = res.data.getAdvSingleRules[0];
          this.arLoading = false;
          const names = item.ruleName.toLowerCase().trim();
          this.dataToCreateRule = {
            mode: 2,
            now: Date.now(),
            item: item,
            goals: this.goals,
            names: this.existingRuleNames,
          };
        })
        .catch((error) => {
          //console.log(error);
          this.arLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    cloneARRule(item) {
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.$apollo
        .query({
          query: GET_SINGLE_RULE_ADV,
          variables: {
            ruleId: item,
            now: Date.now().toString(),
          },
        })
        .then((res) => {
          if (
            res.data.getAdvSingleRules == null ||
            res.data.getAdvSingleRules == undefined
          ) {
            //console.log('No data');
            return;
          }
          const item = res.data.getAdvSingleRules[0];
          this.arLoading = false;
          const names = item.ruleName.toLowerCase().trim();
          this.dataToCreateRule = {
            mode: 5,
            now: Date.now(),
            item: item,
            goals: this.goals,
            names: this.existingRuleNames,
          };
        })
        .catch((error) => {
          //console.log(error);
          this.arLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    deleteARRule(id) {
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.DecisionAlertIcon=1;
      this.decisionAlertFlag = true;
      this.decisionAlertTitle = '';
      this.decisionAlertMessage = this.$t('createAR.sureToDelete');
      this.decisionAlertData = {
        id: id,
        from: 'deleteRule',
        now: Date.now(),
      };
    },

    disableARRule(id) {
      //console.log(id)
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DISABLE_AR_ADV_RULE,
          variables: {
            ruleId: id,
          }
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
          this.$notify.success(this.$t('successMsg.AutoRuleDisSuccessMsg'));
          this.getAllRules();
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(this.$t('successMsg.requestFailMsg'));
        });
    },
    enableARRule(id) {
      // console.log(id)
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: ENABLE_AR_ADV_RULE,
          variables: {
            ruleId: id,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
          this.$notify.success(this.$t('successMsg.AutoRuleEnableMsg'));
          this.getAllRules();
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(this.$t('successMsg.requestFailMsg'));
        });
    },
    deleteARLog(id) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DELETE_AR_LOG,
          variables: {
            ruleId: id,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
        });
    },
    showLogStatusMsg(item) {
      //console.log(item.data)
      const a = [];
      this.appleMsgs = [];
      if (item.logStatus === 'SLACKSENT' || item.logStatus === 'SLACKFAIL') {
        this.infoAlertTitle = 'Messages from Slack!!!';
        if (item.data.error === 'not_in_channel') {
          a.push(
            'The Search Ads Maven App is not added to the specified channel'
          );
        } else if (item.data.error === 'channel_not_found') {
          a.push("Couldn't find the specified channel - might be deleted");
        } else if (item.data.error === 'invalid_auth') {
          a.push(
            'The Search Ads Maven App is not authorized to post to the specified channel'
          );
        } else if (item.data.error === 'missing_scope') {
          a.push(
            'The Search Ads Maven App does not have permissions to post files to the specified channel'
          );
        } else {
          a.push('Unable to post report to the specified channel');
        }
      } else if (
        item.logStatus === 'EMAILSENT' ||
        item.logStatus === 'EMAILFAIL'
      ) {
        this.infoAlertTitle = 'Messages!!!';
        a.push('Unable to send report to the specified email id');
      } else {
        this.infoAlertTitle = 'Messages from Apple!!!';
        if (item.data.error.errors instanceof Array) {
          const errs = item.data.error.errors;
          errs.map((err) => a.push(err.message));
        } else if (item.data.error.errors instanceof Object) {
          const err = item.data.error.errors;
          if (err) a.push(err.message);
          else a.push('Unable to validate the specified changes');
        } else {
          a.push('Unable to validate the specified changes');
        }
      }
      this.infoAlertFlag = true;

      //this.infoAlertMessage = a
      this.appleMsgs = a;
    },

    getAllGoals() {
      if (LSS && LSS.ownApps?.length > 0) {
        const ownApp = LSS.ownApps;
        for (const f of ownApp) {
          if (f.attrExist) {
            this.$apollo
              .query({
                query: GET_GOALS_FROM_APP,
                variables: {
                  appId: f.adamId,
                },
              })
              .then((res) => {
                this.goals.push({
                  adamId: f.adamId,
                  goals: res.data.getGoalsFromApp
                    ? res.data.getGoalsFromApp
                    : [],
                });
              });
          }
        }
      }
    },
    getFunctionsList(type) {
      return this.scriptedArVarMap[type].functions
    },
    getVariablesList(type) {
      return this.scriptedArVarMap[type].vars
    },
    getMiscVariableList(type) {
      return this.scriptedArVarMap[type].misc
    },
    getPropList(type) {
      return this.scriptedArVarMap[type].propList || {}
    },
    addEditor(type) {
      let label;
      switch(type) {
        case 'trigger': {
          label = 'Trigger';
          break;
        }
        case 'campaign': {
          label = 'Campaign Action';
          break;
        }
        case 'adgroup': {
          label = 'Ad Group Action';
          break;
        }
        case 'keyword': {
          label = 'Keyword Action';
          break;
        }
        case 'onetime': {
          label = 'One-Time Action'
          break;
        }
      }
      this.scriptedAutomationsModel.automationSteps.push({
            isValid: true,
            label: label,
            type: type
      });
    },
    deleteScriptedArStep(index) {
      this.scriptedAutomationsModel.automationSteps.splice(index, 1)
    },
    getEditorValues(values, item) {
      item.isValid = values.isValid;
      item.code = values.code;
    },
    testRun() {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: TEST_RUN_SCRIPTED_AR,
          variables: {
            data: JSON.stringify(_.pick(this.scriptedAutomationsModel, ['automationSteps'])),
          },
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          // console.log(res)
          const data = JSON.parse(res.data.testRun.data)
          // console.log(data)
          this.jsonData = data

          if(this.jsonData.length) this.snapshotDialog = true

        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          // console.log(error);
        });
    },
    sarAction(id, data){
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.decisionAlertFlag = true;
      this.decisionAlertTitle = '';
      switch (id) {
        case 1:
          this.decisionAlertMessage = this.$t('aRules.deleteSAR');
          break;
        case 2:
          this.decisionAlertMessage = this.$t('aRules.disableSAR');
          break;
        case 3:
          this.decisionAlertMessage = this.$t('aRules.enableSAR');
          break;
        case 4:
          this.decisionAlertMessage = this.$t('aRules.saveSAR');
          break;
        case 5:
          this.decisionAlertMessage = this.$t('aRules.updateSAR');
          break;
        default:
          break;
      }
      this.decisionAlertData = {
        id: id,
        from: 'SAR',
        now: Date.now(),
        sarData: data
      };
    },
    saveRule(){
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: SAVE_SCRIPTED_AR,
          variables: {
            data: JSON.stringify(_.pick(this.scriptedAutomationsModel, ['automationSteps'])),
            name: Date.now().toString()
          },
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          const data = res.data.saveSAR
          if(data.successMessage === "OK"){
            this.$notify.success('Rule saved successfully');
            this.srdialog = false
            this.getAllScriptedRules();
          }else{
            this.$notify.error('Unable to save rule');
          }
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error('Unable to save rule');
        });
    },
    getAllScriptedRules() {
      this.arScriptedLoading = true;
      this.arScriptedItems = [];

      this.$apollo
        .query({
          query: GET_ALL_RULES_SCRIPTED,
          variables: {
            now: Date.now(),
          },
        })
        .then((res) => {
          if (
            res.data.getAllScriptedRules == null ||
            res.data.getAllScriptedRules == undefined
          ) {
            return;
          }
          this.arScriptedItems = res.data.getAllScriptedRules;
          this.arScriptedItems = this.arScriptedItems.map(e => {
             let formattedDate = 'N/A';
              if (e.lastRun) {
                const momentDate = moment(e.lastRun.LAST_RUN_AT, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
                if (momentDate.isValid()) {
                  formattedDate = 'On ' + momentDate.format('YYYY-MM-DD') + ' At ' + momentDate.format('HH:mm');
                }
              }
            return {
              ...e,
              enabled: !e.disable,
              updatedOn: formattedDate,
              hideLiveRun: e.inProcess ? true : false
            }
          })
          this.arScriptedLoading = false;
          this.existingSARRuleNames = res.data.getAllScriptedRules.map(e => e.ruleName.toLowerCase().trim())
          if(this.editId) {
            if(this.isReadOnly()){
              this.openPermissionReadOnlyPopup()
              return
            }
            const rule = res.data.getAllScriptedRules.find(m => m.ruleId == this.editId)
            if(rule){
              rule.existingNames = this.existingSARRuleNames
              LSS.editSAR = JSON.stringify(rule)
              return this.$router.push({ path:'/scripted-rule', query: {  sar: true }});
            }
          }
        })
        .catch((error) => {
          //console.log(error);
          this.arScriptedLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    deleteSAR(id) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DELETE_SAR,
          variables: {
            ruleId: id,
            now: Date.now().toString()
          },
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          this.$notify.success(this.$t('successMsg.AutoDeleteMsg'));
          this.getAllScriptedRules();
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error(this.$t('successMsg.requestFailMsg'));
        });
    },
    toggleSAR(id, action) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: TOGGLE_SAR,
          variables: {
            ruleId: id,
            action: action,
            now: Date.now().toString()
          },
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          if(action) this.$notify.success(this.$t('successMsg.SCAutoRuleDisSuccessMsg'));
          else this.$notify.success(this.$t('successMsg.SCAutoRuleEnableMsg'));
          this.getAllScriptedRules();
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error(this.$t('successMsg.requestFailMsg'));
        });
    },
    addEditEditor(rule) {
      let label;
      switch(rule.type) {
        case 'trigger': {
          label = 'Trigger';
          break;
        }
        case 'campaign': {
          label = 'Campaign Action';
          break;
        }
        case 'adgroup': {
          label = 'Ad Group Action';
          break;
        }
        case 'keyword': {
          label = 'Keyword Action';
          break;
        }
        case 'onetime': {
          label = 'One-Time Action'
          break;
        }
      }
      this.scriptedAutomationsModel.automationSteps.push({
            ...rule,
            template: rule.code
      });
    },
    createSAR(){
      const rule = {}
      rule.existingNames = this.existingSARRuleNames
      LSS.editSAR = JSON.stringify(rule)
      return this.$router.push({ path:'/scripted-rule'});
    },
    createKWJ(){
      return this.$router.push({ path:'/keyword-journey'});
    },
    createSL(){
      return this.$router.push({ path:'/strategic-losing'});
    },
    createLDW(){
      return this.$router.push({ path:'/late-day-winning'});
    },
    editSAR(rule){
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      rule.existingNames = this.existingSARRuleNames
      LSS.editSAR = JSON.stringify(rule)
      return this.$router.push({ path:'/scripted-rule', query: {  sar: true }});
    },
    updateSAR(rule){
      this.fullscreenLoader = this.$loading.show();
      let data = _.pick(this.scriptedAutomationsModel, ['automationSteps'])
      data = data.automationSteps.map(({template, ...keep}) => keep)
      this.$apollo
        .query({
          query: UPDATE_SCRIPTED_AR,
          variables: {
            data: JSON.stringify({automationSteps: data}),
            name: Date.now().toString(),
            ruleId: rule._id
          },
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          const data = res.data.updateSAR
          if(data.successMessage === "OK"){
            this.$notify.success('Rule updated successfully');
            this.srdialog = false
            this.getAllScriptedRules();
          }else{
            this.$notify.error('Unable to update rule');
          }
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error('Unable to update rule');
        });
    },
    isReadOnly(){
      return isReadOnly()
    },
    openPermissionReadOnlyPopup(){
      this.permissionReadOnlyMetadata={
        date: moment.now()
      }
    },
  },
  beforeRouteEnter(to,from,next) {
    if(from.name && from.name == 'ScriptedRule'){
      next('/sar-proxy')
    }
    next()
  },
  mounted() {
    if(LSS.token && decodeToken(LSS.token)){
      const TeamID = decodeToken(LSS.token).TeamID
      this.enableSARLiveRun = false
      const teamIds = ['C00394-SAM-QA','570f929d-91a0-4d3e-8265-ca023e719d7b','8332aa86-7684-44f8-a2f3-75386840ee35','C00387-SAM-QA']
      this.enableSARLiveRun = teamIds.includes(TeamID)
    }
    this.asaInt = LSS.asaInt
    this.syncStatus = LSS.activeSync;
    Vue.prototype.$sckt.on('syncprogress:event', () => {
      this.syncStatus = LSS.activeSync;
    });

    if(LSS.token && decodeToken(LSS.token)){
      this.tid = decodeToken(LSS.token).TeamID
    }else{
      this.tid = '1234-qwer'
    }
    Vue.prototype.$sckt.on(this.tid, async (testRunEvent) => {      
      console.log(testRunEvent.msg)
      if(!testRunEvent || !testRunEvent.msg) return
      if(testRunEvent.msg.msg == 'CODE_READY'){
        const ruleId = testRunEvent.msg.ruleId
        this.mergedItems.map(m => {
          if(m.ruleId == ruleId){
            m.inProcess = false
            m.hideLiveRun = false
          }
        })
      }
      if(testRunEvent.msg.msg == 'LIVE_RESULTS'){
        const ruleId = testRunEvent.msg.ruleId
        this.mergedItems.map(m => {
          if(m.ruleId == ruleId){
            m.inProcess = false
            m.hideLiveRun = false
          }
        })
      }
    });
    this.locale = localStorage.getItem('locale') || 'en';
    this.getAllRules();
    this.getAllScriptedRules();
    this.getAllLogs();
    this.getAllGoals();
    this.enableMetricsTab();
    updateCLF('automationRules', this);

    const sar = this.$route.query.sar ? this.$route.query.sar : '';
    if (sar) {
      this.$router.replace('/arrules')
      this.tab = 0;
    }

    const editId = (this.$route.query.edit && this.$route.query.id)? this.$route.query.id : null
    this.editId = editId

    this.$root.$on('locale-changed', (locale) => {
      this.locale = locale.localeCode;
    });
  },
};
</script>

<style>
  .v-navigation-drawer__content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>
